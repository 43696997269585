import React from 'react'

const FaqContainer = () => {
  return (
    <div style={{ textAlign: 'left', width: '90%', margin: 'auto auto' }}>
      <h1 id="summer-camp-faq">SUMMER CAMP FAQ</h1>
      <p>Updated 12-01-2024</p>
      <h2>1. What are Rolling Robots Summer Camps?</h2>
      <p>
        Rolling Robots STEM and Robotics Summer Camp is a TECH GENIUS Program
        for kids ages 5 to 15. We believe that your child is a creative tech
        genius. We are offering fun, hands-on, low student to teacher ratio
        Robotics, Coding and Design camps for your child to learn in the summer.
      </p>
      <h2>2. Where are Rolling Robots STEM Summer Camps?</h2>
      <p>
        Rolling Robots is excited to offer in-person on-site summer camps at the
        following Cities in Los Angeles County, Orange County, and Ventura
        County: Palos Verdes, Manhattan Beach, Hermosa Beach, West Los Angeles,
        Santa Monica, Playa Del Rey, Glendale, Pasadena, San Marino, La Canada,
        Irvine, Tustin, Newport Beach, Seal Beach, Thousand Oaks, Westlake
        Village{' '}
      </p>
      <h2>3. What is the daily/weekly schedule?</h2>
      <p>
        Rolling Robots STEM and Robotics Summer Camp offers the most
        flexibility.{' '}
      </p>
      <p>
        Each camp is one week long (Monday - Friday, 5 camp-days total, except
        the week of July 4th). Campers can enroll in the morning session, the
        afternoon session, or both sessions (staying at camp during lunch time).
        The morning session is from 9am to 12pm, and the afternoon session is
        from 1pm to 4pm. The full day session goes from 9am to 4pm with a
        one-hour lunchtime period from 12:00pm - 1:00pm. The lunch hour will be
        complimentary when a camper enrolled in both morning and afternoon
        sessions. A “Boot Camp" is only offered as a full-day option.
      </p>
      <p>
        Campers can be dropped off up to 15 minutes before the session begins
        and be picked up no later than 15 minutes after the session ends. Any
        drop-offs or pick-ups outside of this 15 minutes window will incur an
        extra charge of $1 per minute per camper.
      </p>
      <h2>4. Is there an extended day option?</h2>
      <p>Unfortunately, our camps will not have an extended day option.</p>
      <h2>5. What should my child bring each day?</h2>
      <p>
        Half-day campers should bring snacks and drink, whereas full-day campers
        should bring lunch, snacks and drink for the morning and afternoon
        breaks. Campers can also bring money so they can buy snacks and drinks
        that we have for sale (ranging from $1 to $5 per item). We also highly
        suggest campers bring a water bottle filled with fresh water so they can
        stay fully hydrated. Cups of water are also available for free upon
        request, but it's always good to have your own available.{' '}
      </p>
      <p>
        In consideration of the possibility that some children may have a
        nut/peanut allergy, please <b>DO NOT BRING</b> any food items that may
        contain nuts or peanuts. It’s recommended for the full-day campers to
        bring something they would like to do (reading, drawing, etc.) during
        lunch break.{' '}
      </p>
      <h2>6. Will lunch be provided?</h2>
      <p>
        We do not provide lunch. Please pack a lunch (that does not contain any
        nuts or peanuts) if your child is attending both the morning and
        afternoon camps. Water is available at no charge. We highly recommend
        bringing your own water bottle with your child’s Full Name on it.
        Bottled water, other drinks, and snacks are available for purchase.
      </p>
      <h2>7. Will my child bring home the robots they build during camp?</h2>
      <p>
        Most of the robotics kits we use in our camps will not be taken home
        unless we otherwise specified in the camp description. For example, in
        camps such as Junior Robot Builders and Little Engineers, kids build
        their own robots or projects and take them home.
      </p>
      <h2>
        8. Will my child bring home the game they created on the computer during
        camp?
      </h2>
      <p>
        Yes! Campers will be able to take home any games they create during the
        camp. On the last day of camp, campers can bring a flash drive with at
        least 2GB of available space. We can copy the file to the flash drive.
      </p>
      <h2>
        9. My child has never built a robot or programmed on a computer before.
        Is that OK?
      </h2>
      <p>
        Yes. Rolling Robots summer camps are designed for children of all
        learning backgrounds. We will teach the core concepts of STEM, robotics,
        and computer programming/coding. No prior experience is required except
        for the advanced camps. Please use the age guidelines listed when
        enrolling your child so that they will have the best experience
        possible.
      </p>
      <h2>
        10. My child is a very advanced, high-tech wizard. Will they be bored?
      </h2>
      <p>
        Maybe for about 5 minutes when we are getting everyone started. Rolling
        Robots’ camps are designed to encourage open creativity and deep
        exploration, so once your child is engaged in the process they will be
        challenged to a degree appropriate to their level of skill and
        experience. In case that your child would not enjoy the camp, we are
        always open to learn about the specific situation and make an adjustment
        for the best interest of your child.
      </p>
      <h2>11. What is the student-to-teacher ratio?</h2>
      <p>
        Rolling Robots highly values that each child is unique. We aim for 6
        campers per instructor to ensure that each child gets the attention and
        instructions that they need.{' '}
      </p>
      <h2>12. Do I pay the full amount up front?</h2>
      <p>
        Yes, you pay the full amount up front. But you can add additional
        sessions later as long as space is still available.
      </p>
      <h2 id="cancellation-policy">
        13. What is your change/cancellation/refund policy?
      </h2>
      <p>
        To request a cancellation, or change of your summer camp order, you MUST
        EMAIL us at{' '}
        <a href="mailto:contact@rollingrobots.com">contact@rollingrobots.com</a>
        . The email subject must be{' '}
        <b>“Camp Cancellation Request - Order ###”</b>. After we received your
        email, we will process your request per the following cancellation and
        refund policy:{' '}
      </p>

      <ol>
        <li>
          Up to 21 days (3 weeks) prior to camp start: Free <u>transfer</u> to
          another camp with the same student name for camps in the same calendar
          year,
          <u>the difference of camp fee may apply</u>. To <u>cancel</u> the
          camp, $35 per camp per student for cancellation fee will not be
          refunded.
        </li>
        <li>
          Between 21 and 7 days prior to camp start: Free <u>transfer</u> to
          another camp with the same student name for camps in the same calendar
          year,
          <u>the difference of camp fee may apply</u>. To <u>cancel</u> the
          camp, $100 per camp per student for cancellation fee will not be
          refunded.
        </li>
        <li>Starting 6 days prior to camp date: No Transfer, No Refund. </li>
        <li>No Refund or transfer for any missed/sick camp days.</li>
        <li>
          If Rolling Robots cancels a camp for any reason, we will assist to
          transfer to another camp or refund the full amount.
        </li>
      </ol>

      <h2>
        14. Is there a discount if I sign up for multiple students and sessions?
      </h2>
      <p>
        Yes, we offer the best discount in the same reservation (booking order
        number). If you have more than one camp reserved in the same order,
        there is a 5% discount to the total order automatically calculated at
        checkout. This discount will not be effective when there are other
        seasonal discounts available.
      </p>

      <p>
        Only one discount code allowed for camp made in the same reservation.
        <u>
          There is no discount if multiple camps are made in separate
          reservations.
        </u>
      </p>

      <h2>15. Is there a sibling/friend discount?</h2>
      <p>
        Yes. See the rules for discounts above, "Is there a discount if I sign
        up for multiple students and sessions?"{' '}
      </p>
      <h2>16. Do you have camp during Spring or Winter break?</h2>
      <p>
        Yes, Rolling Robots offers Spring Camps when schools are on spring
        breaks, and Holiday Camps during Thanksgiving, Christmas and New Year at
        Rolling Robots’ year-round locations at Palos Verdes, Glendale, West Los
        Angeles and Irvine.
      </p>
      <h2>16. Do you offer membership programs during summer?</h2>
      <p>
        During summer, Rolling Robots continues to offer membership programs at
        the Rolling Robots’ year-round locations at Palos Verdes, Glendale, West
        Los Angeles and Irvine. The membership programs are from 4pm to 6pm,
        Monday - Friday.
      </p>
    </div>
  )
}

export default FaqContainer
