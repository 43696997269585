import React from 'react'
import { Box, FormControl, Select, MenuItem } from '@mui/material'
const TimeSelect = ({
  startTimeString,
  endTimeString,
  errorValue,
  handleChange,
}) => {
  //custom dropdown time picker
  let hours
  let minutes
  const split = (value, id) => {
    //splits string into individual dropdown values
    if (value == '') {
      switch (id) {
        case 'hours':
          return 12
          break
        case 'ampm':
          return 'PM'
          break
        default:
          return '00'
      }
    } else {
      let array = value.split(':')
      if (id == 'minutes') {
        minutes = array[1]
        return minutes
      }
      if (parseInt(array[0]) < 12) {
        if (id == 'hours') {
          hours = parseInt(array[0])
          return hours
        } else {
          return 'AM'
        }
      } else {
        if (id == 'hours') {
          if (parseInt(array[0]) == 12) {
            hours = parseInt(array[0])
          } else {
            hours = parseInt(array[0]) - 12
          }
          return hours
        } else {
          if (parseInt(array[0]) == 24) {
            return 'AM'
          } else {
            return 'PM'
          }
        }
      }
    }
  }

  const TIME_OPTIONS = [
    //dropdown options
    {
      id: 'hours',
      value: [...Array(12).keys()].map((x) => x + 1),
    },
    {
      id: 'minutes',
      value: ['00', '30'],
    },
    {
      id: 'ampm',
      value: ['AM', 'PM'],
    },
  ]

  const TIME_FIELDS = [
    //start time and end time separate field info
    {
      id: 'startTime',
      value: startTimeString,
      label: 'Start Time',
    },
    {
      id: 'endTime',
      value: endTimeString,
      label: 'End Time',
    },
  ]

  const handleTime = (field, value, id) => {
    //combines separate values back into a string
    let prevTime = field.value.split(':')
    let newTime
    if (id == 'minutes') {
      newTime = prevTime[0] + ':' + value
    } else if (id == 'hours') {
      if (prevTime[0] >= 12 && prevTime[0] !== 24) {
        if (value == 12) {
          newTime = value.toString() + ':' + prevTime[1]
        } else {
          newTime = (value + 12).toString() + ':' + prevTime[1]
        }
      } else {
        if (value == 12) {
          newTime = (value + 12).toString() + ':' + prevTime[1]
        } else {
          newTime = value.toString() + ':' + prevTime[1]
        }
      }
    } else {
      if (prevTime[0] <= 12) {
        newTime = (parseInt(prevTime[0]) + 12).toString() + ':' + prevTime[1]
      } else {
        newTime = (parseInt(prevTime[0]) - 12).toString() + ':' + prevTime[1]
      }
    }
    handleChange(field.id, newTime)
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        {TIME_FIELDS.map((field) => {
          return (
            <div
              key={field.label}
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0px 3px',
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  color: 'grey',
                  height: '12px',
                  zIndex: '1',
                }}
              >
                <span
                  style={{
                    backgroundColor: 'white',
                    fontSize: 12,
                    // left: '10%',
                    position: 'relative',
                  }}
                >
                  {field.label}
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                {TIME_OPTIONS.map((select) => {
                  return (
                    <FormControl
                      key={select.id}
                      error={errorValue}
                      style={{ flexGrow: 1 }}
                    >
                      <Select
                        fullWidth
                        value={split(field.value, select.id)}
                        IconComponent={''}
                        onChange={(e) => {
                          handleTime(field, e.target.value, select.id)
                        }}
                      >
                        {select.value.map((x) => {
                          return (
                            <MenuItem key={select.id + x} value={x}>
                              {x}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )
                })}
              </div>
            </div>
          )
        })}
      </Box>
    </>
  )
}

export default TimeSelect
