import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import LS from '../utils/localStorage'
import { jwtCheck } from '../api/login'
import {
  loginState,
  setDefaultLocation,
  setDefaultRegion
} from '../store/loginSlice'
import { initLocalCart } from '../store/cartSlice'
import { getActiveMessages, getLocationsInfo } from '../api/admin'
import NavigationBar from '../component/navigationBar'
import { logout } from '../store/loginSlice'
import { useNavigate } from 'react-router-dom'
import MessageView from './messageView'
import { GetLocations, getRegion } from '../api/locations'
import { setCourses } from '../store/parentSlice'
import { setUserView, setLocations } from '../store/userSlice'
import { setView } from '../store/subscriptionSlice'
import { getCourses } from '../api/admin'
import Footer from '../component/footer'
import { Helmet } from 'react-helmet'

import {
  Bookmark,
  ShoppingCart,
  Home,
  SupervisorAccount,
  School,
  Category,
  MenuBook,
  Chat,
  AcUnit,
  Person,
  WbSunny,
  Map,
  Today,
  ElectricBolt,
  Info,
  LocalFlorist
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

const NavigationContainer = () => {
  let status = useSelector((state) => state.login.isLoggedIn)
  let user = useSelector((state) => state.login.user)
  let defaultLocation = useSelector((state) => state.login.defaultLocation)
  const [activeMsgs, setActiveMsgs] = useState([])
  const [token, setToken] = useState(null)
  let savedCart = LS.getItem('cart')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const windowLocation = useLocation()

  useEffect(() => {
    async function handleAsync() {
      let resp = await jwtCheck({ token: token })
      if (resp.data.user) {
        dispatch(loginState(resp.data.user))
      }
    }
    if (token !== null) {
      handleAsync()
    }
  }, [token])

  useEffect(() => {
    if (savedCart) {
      dispatch(initLocalCart(savedCart))
    }
  }, [])

  //If the default location is changed, navigate the page to newly selected location
  useEffect(() => {
    if (Object.keys(defaultLocation).length !== 0) {
      try {
        let keys = windowLocation.pathname
          .slice(1, windowLocation.pathname.length)
          .split('/')
        let basePath = keys[0]
        let viewObj = checkType().filter((view) => view.id === basePath)
        if (viewObj.length > 0) {
          handleViewState(viewObj[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [defaultLocation])

  useEffect(() => {
    async function handleAsync() {
      let courses = await getCourses() //Courses from database
      if (courses.data) {
        dispatch(setCourses(courses.data))
      }

      let messages = await getActiveMessages()
      if (messages.success) {
        setActiveMsgs(messages.data)
      } else {
        alert('failed get')
      }
      let locations //Physical locations for all users
      if (user.permissions && user.permissions[0] >= 3) {
        locations = await getLocationsInfo({ params: { uID: user._id } })
      } else {
        locations = await GetLocations()
      }
      if (locations.data) {
        dispatch(setLocations(locations.data))
      }

      // if (user.defaultLocation) {
      //   //User selected default location
      //   let defaultLocationObj = locations.data.filter(
      //     (loc) => loc._id == user.defaultLocation
      //   )
      //   if (defaultLocationObj.length > 0) {
      //     dispatch(setDefaultLocation(defaultLocationObj[0]))
      //   }
      // }
      if (user.defaultRegion) {
        //Admin selected default region
        let defaultRegionObj = await getRegion({
          params: { uID: user._id, regionID: user.defaultRegion }
        })
        if (defaultRegionObj.data) {
          console.log('Default region set')
          dispatch(setDefaultRegion(defaultRegionObj.data))
        } else {
          console.log('Error setting default region')
        }
      }
    }

    handleAsync()
  }, [status])

  let check = LS.getItem('rro-JWT')
  if (check !== null && token === null) {
    setToken(check)
  }

  const SIGNED_OUT_MENU = [
    { value: 'Home', id: 'enroll', icon: <Home /> },
    // {
    //   value: 'Spring Camps',
    //   id: 'spring-camps',
    //   icon: <LocalFlorist />,
    //   hasLocations: true
    // },
    {
      value: 'Holiday Camps',
      id: 'holiday-camps',
      icon: <AcUnit />,
      hasLocations: true
    },
    {
      value: 'Summer Camps',
      id: 'summer-camps',
      icon: <WbSunny />,
      hasLocations: true
    },
    {
      value: 'Other Activities',
      id: 'upcoming-camps',
      icon: <Today />,
      hasLocations: true
      // newTab: true
    },
    // { value: 'Spring Camps', id: 8, icon: <LocalFlorist /> },
    {
      value: 'Memberships',
      id: 'memberships',
      icon: <Bookmark />,
      hasLocations: true
      // newTab: true
    }, //goes to subscriptions container
    {
      value: 'Competition Events',
      id: 'competition-events',
      icon: <ElectricBolt />
    },
    { value: 'FAQ', id: 'faq', icon: <Info /> }
  ]

  let PARENT_MENU = [...SIGNED_OUT_MENU]
  PARENT_MENU.splice(1, 0, {
    value: 'Account View',
    id: `account-view/${user._id}}`,
    icon: <Person />
  })

  const INSTRUCTOR_LIST = [
    { value: 'Home', id: 'enroll', icon: <Home /> },
    { value: 'Parents', id: 'parents', icon: <SupervisorAccount /> },
    { value: 'Students', id: 'students', icon: <School /> },
    { value: 'Memberships', id: 'admin-memberships/all', icon: <Bookmark /> }, //goes to subscriptions container
    { value: 'Upcoming Overview', id: 'upcoming-overview', icon: <MenuBook /> },
    { value: 'Spring Overview', id: 'spring-overview', icon: <MenuBook /> },
    { value: 'Summer Overview', id: 'summer-overview', icon: <MenuBook /> },
    { value: 'Holiday Overview', id: 'holiday-overview', icon: <AcUnit /> },
    { value: 'Email', id: 'email', icon: <Bookmark /> },
    {
      value: 'Competition Events',
      id: 'competition-events',
      icon: <ElectricBolt />
    },
    {
      value: 'Lessons',
      id: 'https://rollingrobots.notion.site/Moving-Lessons-Over-from-com-2638e326d3e04dfd89bc533135ea5713?pvs=74',
      newTab: true,
      icon: <MenuBook />
      // img: 'books.png'
    }
  ]

  let ADMIN_ADDITIONAL = [
    { value: 'Staff', id: 'staff', icon: <Person /> },
    { value: 'Orders', id: 'orders', icon: <ShoppingCart /> },
    { value: 'Locations', id: 'locations', icon: <Map /> },
    { value: 'Workshops', id: 'workshops', icon: <Category /> },
    { value: 'Courses', id: 'courses', icon: <MenuBook /> },
    { value: 'Add Message', id: 'message', icon: <Chat /> }
  ]

  const ADMIN_LIST = [
    ...INSTRUCTOR_LIST.slice(0, 3),
    ...ADMIN_ADDITIONAL,
    ...INSTRUCTOR_LIST.slice(3, INSTRUCTOR_LIST.length)
  ]

  const checkType = () => {
    if (status) {
      if (user.permissions[0] === 4) {
        return ADMIN_LIST
      } else if (user.permissions[0] === 3) {
        return INSTRUCTOR_LIST
      } else {
        return PARENT_MENU
      }
    } else {
      return SIGNED_OUT_MENU
    }
  }

  const handleViewState = (view) => {
    //Show alert for unsaved changes while using alternate check out
    if (windowLocation.pathname === '/alternate-check-out') {
      if (
        window.confirm(
          'You have unsaved changes. Are you sure you want to leave?'
        )
      ) {
        navigate(`${view.id}`)
      }
    } else {
      //Checks if view should be opened in new tab
      if (view.newTab) {
        window.open(view.id)
        //Checks if view has location selection and if user has a default location selected
      }

      // else if (view.hasLocations) {
      //   let path = view.id
      //   if (Object.keys(defaultLocation).length !== 0) {
      //     let urlParam = defaultLocation.name.toLowerCase().replaceAll(' ', '-')
      //     path += '/' + urlParam
      //   }
      //   navigate(`${path}`, { replace: true })
      // }
      else {
        navigate(view.id === 0 ? '/' : `${view.id}`)
      }
    }
    //Reset location within pages in redux store when menu is selected
    dispatch(setUserView(0))
    //Special case for Memberships to reset to Table View when Memberships is selected
    if (view.value === 'Memberships') {
      dispatch(setView(0))
    }
  }

  const handleLogOut = () => {
    LS.deleteItem('rro-JWT')
    dispatch(logout()) //clears login slice
    dispatch({ type: 'USER_LOGOUT' }) //clears entire store
    navigate('/')
  }

  const handleLogIn = () => {
    navigate('login')
  }

  let styles = {
    footer: { borderTop: '1px solid grey' },
    box: {
      width: '90%',
      maxWidth: '1300px',
      margin: 'auto auto'
    },
    yellow: '#F3C34B',
    lightGrey: '#F2F4F7',
    red: '#EE4239',
    green: '#6EA44F',
    lightBlue: '#ADC5E7',
    blue: '#56A1D6',
    purple: '#8F7EB5',
    darkGrey: '#3e3e3e'
  }

  return (
    <div>
      <Helmet>
        <title>Rolling Robots - Enroll Today!</title>
        <meta
          name='description'
          content='Discover the perfect summer camp at Rolling Robots! We offer hands-on fun and learning that kids aged 5-15 will love. Join us at our 20+ locations in Southern California!'
        />
      </Helmet>
      <MessageView activeMsgs={activeMsgs} />
      <NavigationBar
        items={checkType()}
        handleViewState={handleViewState}
        handleLogOut={handleLogOut}
        handleLogIn={handleLogIn}
      />
      <div style={{ minHeight: '100vh' }}>
        <Outlet />
      </div>
      <Footer styles={styles} />
    </div>
  )
}

export default NavigationContainer
