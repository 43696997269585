import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormControl,
  IconButton,
  TextField,
  Button,
  Box,
  Typography,
  Chip,
  OutlinedInput,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CONSTANTS from '../../../constants'
import { getActivities, getLinkedActivity } from '../../../api/admin'
import getDayString from '../../../utils/getDayString'
import CloseIcon from '@mui/icons-material/Close'
import TimeSelect from '../timeSelect'

const ActivityRow = ({
  item,
  index,
  courseOptions,
  handleChange,
  removeActivity,
  errorList,
  locationID,
  instructorOptions,
}) => {
  const uID = useSelector((state) => state.login.user._id)

  const [openSelect, setOpenSelect] = useState(false)

  const [linkedActivity, setLinkedActivity] = useState(null)
  const [oldLinkedActivity, setOldLinkedActivity] = useState(null)
  const [showLinkOptions, setShowLinkOptions] = useState(false)
  const [disableLink, setDisableLink] = useState(false)

  const [selectedSubscription, setSelectedSubscription] = useState('')
  const subscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  ).filter((sub) => sub.locationID === locationID)

  const [showActivityOptions, setShowActivityOptions] = useState(false)
  const [activityOptions, setActivityOptions] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('')

  useEffect(() => {
    async function handleAsync() {
      let resp = await getLinkedActivity({
        params: { uID: uID, activityID: item.linkedActivity },
      })
      if (resp.success) {
        setLinkedActivity(resp.data)
        setOldLinkedActivity(resp.data)
      } else {
        alert('There was a problem getting the linked activity')
      }
    }
    if (item.linkedActivity) {
      handleAsync()
    }
  }, [])

  const handleOpen = () => {
    setOpenSelect(true)
  }

  const handleClose = () => {
    setOpenSelect(false)
  }

  const courseNameCheck = (name) => {
    return courseOptions.filter((option) => option.name == name)[0]['_id']
  }

  const selectSubscription = async (subscription) => {
    //Set name of selected subscription for dropdown menu
    let tmpSubs = [...subscriptions]
    let tmpSub = tmpSubs.filter((sub) => sub._id === subscription._id)
    setSelectedSubscription(tmpSub[0])

    //API call for that subscription's activities
    let resp = await getActivities({
      params: { uID: uID, activityIDs: subscription.activities },
    })
    if (resp.success) {
      //Remove selected activity from list
      let tmpArr = []
      resp.data.forEach((activity) => {
        if (activity.instance._id !== item.id) {
          tmpArr.push(activity)
        }
      })

      setActivityOptions(tmpArr)
      setShowActivityOptions(true)
    } else {
      alert('There was a problem retrieving activities. Please try again')
    }
  }

  const selectActivity = (activity) => {
    setSelectedActivity(activity)
    handleChange('linkedActivity', activity.instance._id, index)
  }

  const handleActivityCancel = () => {
    setShowLinkOptions(false)
    setLinkedActivity(oldLinkedActivity)
    if (oldLinkedActivity) {
      handleChange('linkedActivity', oldLinkedActivity.instance._id, index)
    } else {
      handleChange('linkedActivity', null, index)
    }
  }

  const handleRemoveLink = () => {
    setLinkedActivity(null)
    setDisableLink(true)
    handleChange('deleteLink', linkedActivity.instance, index)
  }

  const handleChangeAtIndex = (type, value) => {
    handleChange(type, value, index)
  }

  return (
    <Grid container item key={item.courseName + '_' + index}>
      <Grid item xs={11}>
        <FormControl fullWidth style={{ margin: '5px' }}>
          <InputLabel id="select-courses">Course</InputLabel>
          <Select
            labelId="select-courses"
            id="select-courses"
            value={
              item.courseId || item.courseId == ''
                ? item.courseId
                : courseNameCheck(item.courseName)
            }
            error={errorList.includes('courseId_' + index)}
            label="Course"
            onChange={(e) => {
              handleChange('courseId', e.target.value, index)
            }}
          >
            {courseOptions.map((option, i) => {
              return (
                <MenuItem key={option.name + '_' + i} value={option['_id']}>
                  {option.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            removeActivity(index, item.id)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        style={{
          padding: 0,
        }}
      >
        <TimeSelect
          startTimeString={item.startTime}
          endTimeString={item.endTime}
          errorValue={errorList.includes('rangeError_' + index)}
          handleChange={handleChangeAtIndex}
        />
      </Grid>

      <Grid item xs={8} md={4} style={{ marginTop: '12px' }}>
        <FormControl fullWidth>
          <InputLabel id="select-days">Days of the Week</InputLabel>
          <Select
            open={openSelect}
            onClose={handleClose}
            onOpen={handleOpen}
            labelId="select-days"
            id="select-days"
            fullWidth
            error={errorList.includes('days_' + index)}
            value={item.days}
            label="Days of the Week"
            multiple
            onChange={(e) => {
              handleChange('days', e.target.value, index)
            }}
          >
            {CONSTANTS.DAYS_OF_WEEK.map((option) => {
              return (
                <MenuItem key={option.short} value={option.short}>
                  {option.long}
                </MenuItem>
              )
            })}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                borderTop: '1px solid grey',
              }}
            >
              <Button onClick={() => handleClose()}>Done</Button>
            </div>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4} md={2} style={{ marginTop: '12px' }}>
        <TextField
          fullWidth
          value={item.cap}
          id={item.key}
          key={item.key}
          error={errorList.includes('cap_' + index)}
          label="Capacity"
          onChange={(e) => {
            handleChange('cap', e.target.value, index)
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth style={{ marginTop: '10px' }}>
          <InputLabel>Select Instructor</InputLabel>
          <Select
            value={item.instructors}
            label="Instructor"
            onChange={(e) => {
              handleChange('instructors', e.target.value, index)
            }}
            //error={errorList.includes('instructorID')}
            name="instructorID"
            multiple
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((instructorId) => (
                  <Chip
                    label={
                      instructorOptions?.find((e) => e._id === instructorId)
                        ?.info?.name?.fName
                    }
                  />
                ))}
              </Box>
            )}
          >
            <MenuItem key={''} value={''}>
              None
            </MenuItem>
            {instructorOptions.map((option) => {
              return (
                <MenuItem key={option._id} value={option._id}>
                  {option.info.name.fName} {option.info.name.lName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>

      {!showLinkOptions ? (
        <Grid
          item
          xs={12}
          style={{ marginTop: '12px', display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="h6" style={{ textAlign: 'left' }}>
            Linked Activity:
          </Typography>
          {linkedActivity === null ? (
            <Typography variant="h6"> &nbsp;(none)</Typography>
          ) : (
            <Typography variant="h6">
              &nbsp;{linkedActivity.course.name} -{' '}
              {getDayString(linkedActivity.instance.day)}
            </Typography>
          )}

          {linkedActivity === null ? (
            <>
              {disableLink ? (
                <Typography
                  style={{
                    color: 'red',
                  }}
                >
                  Update Subscription before linking to another activity
                </Typography>
              ) : (
                <IconButton
                  aria-label="link"
                  color="primary"
                  onClick={() => {
                    setShowLinkOptions(true)
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          ) : (
            <IconButton
              aria-label="link"
              color="primary"
              onClick={() => {
                handleRemoveLink()
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'left' }}>Step 1</Typography>
            <FormControl fullWidth style={{ margin: '5px' }}>
              <InputLabel id="select-subscription">Subscription</InputLabel>
              <Select
                labelId="select-subscription"
                id="select-subscription"
                value={selectedSubscription.name}
                label="Subscription"
                onChange={(e) => {
                  selectSubscription(e.target.value)
                }}
              >
                {subscriptions.map((option) => {
                  return (
                    <MenuItem key={option._id} value={option}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          {showActivityOptions ? (
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'left' }}>Step 2</Typography>
              <FormControl fullWidth style={{ margin: '5px' }}>
                <InputLabel id="select-activity">Activity</InputLabel>
                <Select
                  labelId="select-activity"
                  id="select-activity"
                  value={selectedActivity?.course?.name}
                  label="activity"
                  onChange={(e) => {
                    selectActivity(e.target.value)
                  }}
                >
                  {activityOptions.map((option) => {
                    return (
                      <MenuItem key={option.instance._id} value={option}>
                        {getDayString(option.instance.day)}:{' '}
                        {option.course.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <></>
          )}
          <Button onClick={handleActivityCancel}>Cancel</Button>
        </>
      )}
    </Grid>
  )
}

export default ActivityRow
