import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Grid,
  Box,
  useMediaQuery,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  Tooltip,
  Divider
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { updateStudent } from '../../store/parentSlice'
import { untagStudent, tagStudent, getTagsWithoutStudents } from '../../api/tag'
import { pauseSubscription } from '../../api/subscription'
import {
  setStudent,
  setSelectedSubscription,
  setPaymentData
} from '../../store/subscriptionSlice'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import ErrorIcon from '@mui/icons-material/Error'
import PauseSubscription from '../subscriptionPause/pauseSubscription'
import CONSTANTS from '../../constants'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import dateHandler from '../../utils/dateHandler'
import LaunchIcon from '@mui/icons-material/Launch'
import { setSelectedUser } from '../../store/userSlice'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ChangeSubscription from './edit/changeSubscription'
import CancelSubscription from './edit/cancelSubscription'
import AlternatePaymentModal from '../adminViews/alternatePaymentModal'
import PaymentPlanToolTip from '../paymentPlanToolTip'
import SubscriptionAttendance from './subscriptionAttendance'
import EditPause from '../subscriptionPause/editPause'
import stripeData from '../../utils/stripeData'
import DeleteTagDialog from './deleteTagDialog'
import AchPaymentStatus from '../adminViews/achPaymentStatus'

const SubscriptionBox = ({
  data,
  permissions,
  subscription,
  allActivities,
  inactive,
  handleStatus
}) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const uID = useSelector((state) => state.login.user._id)
  const selectedUser = useSelector((state) => state.user.selectedUser)
  const [showTagBox, setShowTagBox] = useState(false)
  const [tagOptions, setTagOptions] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  let isStaff = false
  if (permissions > 3) {
    isStaff = true
  }

  const [showPauseBox, setShowPauseBox] = useState(false)
  const [subscriptionToPause, setSubscriptionToPause] = useState(null)
  const [pauseConfirm, setPauseConfirm] = useState(false)
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString('en', 'America/Los_Angeles')
  )
  const [returnDate, setReturnDate] = useState(
    new Date().toLocaleDateString('en', 'America/Los_Angeles')
  )
  const [pauseLoading, setPauseLoading] = useState(false)
  const [pauseError, setPauseError] = useState(false)

  const [showPauseEdit, setShowPauseEdit] = useState(false)
  const [subscriptionToEdit, setSubscriptionToEdit] = useState(null)

  const [openChangeSub, setOpenChangeSub] = useState(false)
  const [openCancelSub, setOpenCancelSub] = useState(false)

  const [showAttendance, setShowAttendance] = useState(false)

  let subscriptionActivities = allActivities.filter(
    (activity) => activity.instance.subscriptionID === subscription._id
  )
  subscriptionActivities.sort(
    (a, b) =>
      CONSTANTS.SHORT_DAY_INDEX[a.instance.day] -
      CONSTANTS.SHORT_DAY_INDEX[b.instance.day]
  )

  //Determine if student is paused
  let dateOfReturn = null
  let dateOfStart = null
  let pausedCheck = subscription?.paused?.filter(
    (subItem) => subItem.studentID === data._id
  )
  if (pausedCheck?.length > 0) {
    let pausedDate = pausedCheck[0].returnDate
    let pausedStart = pausedCheck[0].startDate
    if (pausedDate === 'indefinite') {
      dateOfReturn = 'indefinitely'
    } else {
      dateOfReturn = dateHandler.toUSDate(pausedDate)
    }
    if (pausedStart) {
      dateOfStart = dateHandler.toUSDate(pausedStart)
    }
  }

  //Determine if student started after signing up through Stripe
  let delayedStart = null
  let delayedCheck = subscription.delayedStart?.filter(
    (sub) => sub.studentID === data._id
  )
  if (delayedCheck && delayedCheck.length > 0) {
    delayedStart = delayedCheck[0].startDate
  }

  //Variables for DeleteTagDialog
  const [showConfirmDeleteTag, setShowConfirmDeleteTag] = useState(false)
  const [tagToRemove, setTagToRemove] = useState(null)

  const handleShowTagRemoveConfirm = (tag) => {
    setTagToRemove(tag)
    setShowConfirmDeleteTag(true)
  }

  const handleCloseTagRemove = () => {
    setTagToRemove(null)
    setShowConfirmDeleteTag(false)
  }

  const handleDeleteTag = async () => {
    let resp = await untagStudent({
      uID: uID,
      studentID: data._id,
      tagID: tagToRemove._id
    })
    if (resp.success) {
      // Find the tag within the subscription
      let tmpSubscriptions = [...data.subscriptionInfo]
      let tmpSub = tmpSubscriptions.filter(
        (sub) => sub._id === subscription._id
      )[0]
      tmpSub = { ...tmpSub }
      let tmpTags = tmpSub.tags.filter(
        (tmpTag) => tmpTag._id !== tagToRemove._id
      )
      tmpSub.tags = tmpTags

      //Update the subscription within the student
      let subIndex = tmpSubscriptions.findIndex(
        (sub) => sub._id === subscription._id
      )
      tmpSubscriptions.splice(subIndex, 1, tmpSub)
      let tmpStudent = { ...data }
      tmpStudent.subscriptionInfo = tmpSubscriptions

      //Update the student
      let tmpUser = { ...selectedUser }
      let studentIndex = tmpUser.students.findIndex(
        (student) => student._id === data._id
      )
      let tmpStudents = [...tmpUser.students]
      tmpStudents.splice(studentIndex, 1, tmpStudent)
      tmpUser.students = tmpStudents

      //Update the Redux Store
      dispatch(setSelectedUser(tmpUser))
      handleCloseTagRemove()
    } else {
      alert(resp)
    }
  }

  const handleOpenTagBox = async () => {
    let resp = await getTagsWithoutStudents({
      params: { subscriptionID: subscription._id, uID: uID }
    })
    if (resp.success) {
      let newTags = []
      //Remove any tags already assigned to the student
      resp.data.forEach((elm) => {
        if (!elm.students.includes(data._id)) {
          newTags.push(elm)
        }
      })
      setTagOptions(newTags)
      setShowTagBox(true)
    } else {
      alert('There was an error retrieving tags for this membership')
    }
  }

  const handleCloseTagBox = () => {
    setShowTagBox(false)
    setTagOptions([])
    setShowLoading(false)
  }

  const selectNewTag = async (newTag) => {
    setShowLoading(true)
    let resp = await tagStudent({
      uID: uID,
      studentID: data._id,
      tagID: newTag._id
    })

    if (resp.success) {
      // Find the tag within the subscription
      let tmpSubscriptions = [...data.subscriptionInfo]
      let tmpSub = tmpSubscriptions.filter(
        (sub) => sub._id === subscription._id
      )[0]
      tmpSub = { ...tmpSub }
      let tmpTags = [...tmpSub.tags]
      tmpTags.push(newTag)
      // let tmpTags = tmpSub.tags.filter((tmpTag) => tmpTag._id !== tag._id)
      tmpSub.tags = tmpTags

      //Update the subscription within the student
      let subIndex = tmpSubscriptions.findIndex(
        (sub) => sub._id === subscription._id
      )
      tmpSubscriptions.splice(subIndex, 1, tmpSub)
      let tmpStudent = { ...data }
      tmpStudent.subscriptionInfo = tmpSubscriptions

      //Update the student
      let tmpUser = { ...selectedUser }
      let studentIndex = tmpUser.students.findIndex(
        (student) => student._id === data._id
      )
      let tmpStudents = [...tmpUser.students]
      tmpStudents.splice(studentIndex, 1, tmpStudent)
      tmpUser.students = tmpStudents

      //Update the Redux Store
      dispatch(setSelectedUser(tmpUser))

      handleCloseTagBox()
    } else {
      alert(resp)
    }
  }

  const handleSubscriptionPause = (subscription) => {
    setSubscriptionToPause(subscription)
    setShowPauseBox(true)
  }

  const handleCloseSubscriptionPause = () => {
    setPauseConfirm(false)
    setReturnDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
    setStartDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
    setPauseLoading(false)
    setPauseError(false)
    setShowPauseBox(false)
  }

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
  }

  const handleReturnDateChange = (newDate) => {
    setReturnDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
    setPauseConfirm(true)
  }

  const handlePauseCancel = () => {
    setPauseConfirm(false)
    setReturnDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
  }

  const handlePauseSubmit = async () => {
    setPauseLoading(true)
    setPauseError(false)
    let today = new Date()
    let futureDate = new Date(returnDate)
    let time = futureDate.getTime() - today.getTime()
    let formattedTime = time / (1000 * 3600 * 24)

    //format start time
    let start = new Date(startDate)
    let startTime = start.getTime() - today.getTime()
    let formattedStartTime = startTime / (1000 * 3600 * 24)
    //format end time
    let end = new Date(returnDate)
    let endTime = end.getTime() - today.getTime()
    let formattedEndTime = endTime / (1000 * 3600 * 24)
    if (formattedTime >= 14) {
      let resp = await pauseSubscription({
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        //priceID: subscriptionToPause.priceID,
        studentID: data._id,
        subscriptionID: subscriptionToPause._id,
        productID: subscriptionToPause.productID
      })
      if (resp.success) {
        //update redux store
        if (resp.data.subscription !== null) {
          let tmpData = { ...data }
          let tmpSubInfo = []

          tmpData.subscriptionInfo?.forEach((tmpSub) => {
            if (tmpSub._id !== resp.data.subscription._id) {
              tmpSubInfo.push(tmpSub)
            } else {
              tmpSubInfo.push(resp.data.subscription)
            }
          })
          tmpData['subscriptionInfo'] = tmpSubInfo
          dispatch(updateStudent(tmpData))

          let tmpUser = { ...selectedUser }
          let tmpStudents = []

          tmpUser.students.forEach((student) => {
            if (student._id !== tmpData._id) {
              tmpStudents.push(student)
            } else {
              tmpStudents.push(tmpData)
            }
          })
          tmpUser['students'] = tmpStudents
          dispatch(setSelectedUser(tmpUser))
        } else {
          alert(
            'There was an error pausing the membership. Please contact a network administrator with the student and membership details.'
          )
        }

        handleCloseSubscriptionPause()
      } else {
        alert(resp)
      }
    } else {
      setPauseError(true)
      setPauseConfirm(false)
      setPauseLoading(false)
    }
  }

  const handleEditPause = (subscription) => {
    setSubscriptionToEdit(subscription)
    setShowPauseEdit(true)
  }

  const handleClosePauseEdit = () => {
    setSubscriptionToEdit(null)
    setShowPauseEdit(false)
  }

  const handleOpenChangeSub = (sub) => {
    dispatch(setSelectedSubscription(sub))
    //Assign credit data to student if necessary
    let tmpStudent = { ...data }
    if (sub.credit) {
      tmpStudent.credit = sub.credit
    }
    dispatch(setStudent(tmpStudent))
    setOpenChangeSub(true)
  }

  const handleCloseChangeSub = () => {
    setOpenChangeSub(false)
  }

  const handleOpenCancelSub = (sub) => {
    dispatch(setSelectedSubscription(sub))
    if (sub.credit) {
      dispatch(setPaymentData(sub.credit))
    } else if (sub.stripe) {
      dispatch(setPaymentData(sub.stripe))
    }
    dispatch(setStudent(data))
    setOpenCancelSub(true)
  }

  const handleCloseCancelSub = () => {
    setOpenCancelSub(false)
  }

  const handleShowAttendance = (sub) => {
    dispatch(setSelectedSubscription(sub))
    dispatch(setStudent(data))
    setShowAttendance(true)
  }

  const handleCloseAttendance = () => {
    dispatch(setSelectedSubscription(null))
    dispatch(setStudent(null))
    setShowAttendance(false)
  }

  const CreditDetails = ({ credit, border }) => {
    return (
      <React.Fragment key={credit._id}>
        {border ? (
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: '5px' }} />
          </Grid>
        ) : null}
        <Grid item xs={5}>
          <Typography variant='body2' align='left'>
            PO Number
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1' align='left' sx={{ flexGrow: 1 }}>
            {credit.paymentID}
          </Typography>
          <AlternatePaymentModal
            selectedCredit={credit._id}
            displayStatus={credit.confirmed}
            handleStatus={handleStatus}
            iconOnly={true}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography variant='body2' align='left'>
            Start Date{' '}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant='body1' align='left'>
            {new Date(credit.startDate + ' ').toDateString()}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography variant='body2' align='left'>
            End Date{' '}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant='body1' align='left'>
            {new Date(credit.endDate + ' ').toDateString()}
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <>
      {allActivities.length === 0 && !inactive ? (
        <>loading...</>
      ) : (
        <React.Fragment key={subscription._id}>
          <Grid container sx={{ marginTop: '15px' }}>
            {mobile ? (
              <></>
            ) : (
              <Grid container item xs={2}>
                <Box
                  component='img'
                  alt='Subscription Icon'
                  sx={{ width: '70%', margin: '10px auto auto auto' }}
                  src={require(`../../images/courseIcons/${
                    subscription.icon || 'robie.png'
                  }`)}
                />
              </Grid>
            )}

            <Grid container item xs={10} sx={{ alignContent: 'flex-start' }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant='h5'
                  display='inline'
                  sx={{
                    color: inactive ? '#5c5c5c' : 'primary.main',
                    textAlign: 'left'
                  }}
                >
                  {subscription.name || 'N/A'}
                </Typography>
                {subscription.name && isStaff ? (
                  <IconButton
                    sx={{ color: inactive ? '#5c5c5c' : 'primary.main' }}
                    aria-label='more details link'
                    onClick={() => navigate(`/admin-memberships/all/${subscription._id}`)}
                    size='small'
                  >
                    <LaunchIcon />
                  </IconButton>
                ) : (
                  <></>
                )}

                {isStaff ? (
                  <Tooltip describeChild title='View Attendance'>
                    <IconButton
                      sx={{ color: inactive ? '#5c5c5c' : 'primary.main' }}
                      aria-label='attendance dialog'
                      onClick={() => handleShowAttendance(subscription)}
                      size='small'
                    >
                      <DateRangeIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}

                {isStaff && !inactive ? (
                  <>
                    {!delayedStart ? (
                      <>
                        {dateOfReturn ? (
                          <Tooltip describeChild title='Resume Membership'>
                            <IconButton
                              color='primary'
                              aria-label='resume dialog'
                              onClick={() => handleEditPause(subscription)}
                              size='small'
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip describeChild title='Pause Membership'>
                            <IconButton
                              color='primary'
                              aria-label='pause dialog'
                              onClick={() =>
                                handleSubscriptionPause(subscription)
                              }
                              size='small'
                            >
                              <PauseCircleIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <Tooltip describeChild title='Switch Membership'>
                      <IconButton
                        color='primary'
                        aria-label='switch dialog'
                        onClick={() => handleOpenChangeSub(subscription)}
                        size='small'
                      >
                        <ChangeCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip describeChild title='Cancel Membership'>
                      <IconButton
                        color='primary'
                        aria-label='cancel dialog'
                        onClick={() => handleOpenCancelSub(subscription)}
                        size='small'
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              {dateOfReturn ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <ErrorIcon color='primary' />
                  {dateOfStart ? (
                    <Typography>
                      Paused from {dateOfStart} to {dateOfReturn}
                    </Typography>
                  ) : (
                    <Typography>Paused {dateOfReturn}</Typography>
                  )}
                </Grid>
              ) : (
                <></>
              )}

              {!inactive ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant='body2' align='left'>
                      Start Date{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {delayedStart ? (
                      <Typography variant='body1' align='left'>
                        {dateHandler.formatDate(delayedStart)}
                      </Typography>
                    ) : (
                      <Typography variant='body1' align='left'>
                        {subscription.stripe
                          ? stripeData.getStartDate(subscription.stripe)
                          : dateHandler.formatDate(
                              subscription.credit?.startDate + ' '
                            )}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant='body2' align='left'>
                      {subscription.stripe ? 'Next Invoice ' : 'End Date'}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant='body1' align='left'>
                      {subscription.stripe
                        ? stripeData.getBillDue(subscription.stripe)
                        : dateHandler.formatDate(
                            subscription.credit?.endDate + ' '
                          )}
                    </Typography>
                  </Grid>

                  {subscription.stripe?.cancellation_details.reason ===
                  'cancellation_requested' ? (
                    <>
                      <Grid item xs={5}>
                        <Typography variant='body2' align='left'>
                          Cancelling
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant='body1' align='left'>
                          At period end
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {isStaff ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant='body2' align='left'>
                      Product ID
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant='body1' align='left'>
                      {subscription.productID}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={5}>
                <Typography variant='body2' align='left'>
                  Location
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant='body1' align='left'>
                  {subscription.locationName || 'N/A'}
                </Typography>
              </Grid>

              {subscription.tags?.length > 0 || isStaff ? (
                <>
                  <Grid item xs={5} align='left' style={{ display: 'flex' }}>
                    <Typography
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Tags:&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={7} align='left'>
                    {subscription.tags?.map((tag) => {
                      if (isStaff) {
                        return (
                          <Chip
                            label={tag.name}
                            color='error'
                            size='small'
                            style={{ marginLeft: '5px' }}
                            onDelete={() => {
                              handleShowTagRemoveConfirm(tag)
                            }}
                          />
                        )
                      } else {
                        return (
                          <Chip
                            label={tag.name}
                            color='error'
                            size='small'
                            style={{ marginLeft: '5px' }}
                          />
                        )
                      }
                    })}
                    {isStaff ? (
                      <IconButton
                        aria-label='link'
                        color='primary'
                        onClick={() => {
                          handleOpenTagBox(subscription)
                        }}
                      >
                        <AddIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {subscriptionActivities.length > 0 ? (
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle2'
                    align='left'
                    sx={{ marginBottom: '-5px', marginTop: '10px' }}
                  >
                    Activities
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              {subscriptionActivities.map((activity) => {
                return (
                  <React.Fragment key={activity.instance._id}>
                    <Grid item xs={5}>
                      <Typography variant='body2' align='left'>
                        {activity.course.name}{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant='body1' align='left'>
                        {CONSTANTS.DAYS_SHORT_TO_LONG[activity.instance.day]}{' '}
                        {dateHandler.toAbbreviatedTime(
                          activity.instance.startTime
                        )}{' '}
                        -{' '}
                        {dateHandler.toAbbreviatedTime(
                          activity.instance.endTime
                        )}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )
              })}
              <Grid item xs={12}>
                <Typography
                  variant='subtitle2'
                  align='left'
                  sx={{ marginBottom: '-5px', marginTop: '10px' }}
                >
                  Payment Details
                </Typography>
              </Grid>
              {subscription.stripe ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant='body2' align='left'>
                      Payment Plan{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant='body1' align='left'>
                      ${stripeData.getPaymentPlan(subscription.stripe)}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {subscription.credit ? (
                    <>
                      <Grid item xs={5}>
                        <Typography variant='body2' align='left'>
                          Payment Plan{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant='body1' align='left'>
                          {subscription.credit?.paymentType}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {isStaff ? (
                        <>
                          <Grid item xs={5}>
                            <Typography variant='body2' align='left'>
                              Payment Plan{' '}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Typography variant='body1' align='left'>
                              Not Found
                            </Typography>
                            <PaymentPlanToolTip />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}

              {subscription.credit ? (
                <>
                  <CreditDetails credit={subscription.credit} />
                </>
              ) : (
                <></>
              )}
              {subscription.ach ? (
                <><Grid xs={12}>
                  <AchPaymentStatus displayStatus={subscription.ach.status} />
                </Grid></>
              ) : <></>}
              {subscription.prevCredits ? (
                [...subscription.prevCredits]
                  .reverse()
                  .map((prevCredit) => (
                    <CreditDetails credit={prevCredit} border={true} />
                  ))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={showTagBox}
            onClose={handleCloseTagBox}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'rgba(240, 240, 240, 0.3)'
              },
              '& .MuiPaper-root': { boxShadow: 'none' }
            }}
            fullWidth
            maxWidth='xs'
          >
            <DialogTitle style={{ textAlign: 'center' }}>
              Select Tag
              <IconButton
                style={{ position: 'absolute', right: '20px', top: '12px' }}
                onClick={() => {
                  handleCloseTagBox()
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {showLoading ? (
              <Typography>Loading...</Typography>
            ) : (
              <>
                {tagOptions.length > 0 ? (
                  <FormControl fullWidth>
                    <InputLabel>Select Tag</InputLabel>
                    <Select
                      labelId='select-tag'
                      id='select-tag'
                      label='tag'
                      onChange={(e) => {
                        selectNewTag(e.target.value)
                      }}
                    >
                      {tagOptions.map((tag) => {
                        return (
                          <MenuItem key={tag._id} value={tag}>
                            {tag.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography>
                    This membership has no tags or student is already attached
                    to all tags
                  </Typography>
                )}
              </>
            )}
          </Dialog>

          <SubscriptionAttendance
            open={showAttendance}
            handleClose={handleCloseAttendance}
            activities={subscriptionActivities}
          />

          <PauseSubscription
            open={showPauseBox}
            handleClose={handleCloseSubscriptionPause}
            error={pauseError}
            confirm={pauseConfirm}
            subscription={subscriptionToPause}
            handleReturnDateChange={handleReturnDateChange}
            handleStartDateChange={handleStartDateChange}
            handleCancel={handlePauseCancel}
            handleSubmit={handlePauseSubmit}
            loading={pauseLoading}
            startDate={startDate}
            returnDate={returnDate}
          />

          <ChangeSubscription
            open={openChangeSub}
            handleCloseSub={handleCloseChangeSub}
          />

          <CancelSubscription
            open={openCancelSub}
            close={handleCloseCancelSub}
          />

          <EditPause
            open={showPauseEdit}
            handleClose={handleClosePauseEdit}
            subscription={subscriptionToEdit}
            student={data}
            source={'account'}
          />
          <DeleteTagDialog
            tag={tagToRemove}
            showConfirmDeleteTag={showConfirmDeleteTag}
            student={data}
            handleConfirm={handleDeleteTag}
            handleCloseTagDelete={handleCloseTagRemove}
          />
        </React.Fragment>
      )}
    </>
  )
}

export default SubscriptionBox
