import React, { useEffect, useState } from 'react'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Paper,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { GetLocations } from '../../../api/locations'
import { getCourses, getInstructors } from '../../../api/admin'
import { useSelector, useDispatch } from 'react-redux'
import {
  addSubscription,
  updateSub,
  updateLinkedSubs,
} from '../../../store/subscriptionSlice'
import ImageSelector from '../../imageSelector'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import ActivityRow from './activityRow'
import {
  createSubscription,
  getSubscriptionActivities,
  updateSubscription,
} from '../../../api/admin'
import cards from '../../util/cards'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import timeHandler from '../../../utils/timeHandler'

const SubscriptionForm = ({ setCurrTab }) => {
  const BlankCard = cards.blankCard
  const SUBSCRIPTION_FIELDS = [
    {
      id: 'name',
      label: 'Membership Name',
      type: 'text',
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
    },
    {
      id: 'price',
      label: 'Default Price',
      type: 'number',
    },
    {
      id: 'numOfDays',
      label: 'Days per Week',
      type: 'number',
    },
    {
      id: 'link',
      label: 'Membership URL',
      type: 'text',
    },
  ]

  const dispatch = useDispatch()
  const isEdit = useSelector((state) => state.subscription.edit)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  let initialValues

  if (isEdit) {
    let subscriptionPrice = subscription.price
    if (subscriptionPrice >= 1000) {
      subscriptionPrice = subscriptionPrice / 100
    }
    initialValues = {
      name: subscription.name,
      price: subscriptionPrice,
      altPrices: subscription.altPrices,
      numOfDays: subscription.numOfDays,
      link: subscription.link,
      description: subscription.description,
      icon: subscription.icon,
      locationID: subscription.locationID,
      requiresInvite: subscription.requiresInvite,
      active: subscription.active,
    }
  } else {
    initialValues = {
      name: '',
      price: '',
      altPrices: [],
      numOfDays: 0,
      link: '',
      description: '',
      icon: '',
      locationID: '',
      requiresInvite: false,
      active: true,
    }
  }

  let baseActivity = {
    days: [],
    startTime: '10:00',
    endTime: '12:00',
    courseId: '',
    instructors: [],
    linkedActivity: null,
  }

  const [showIconSelect, setShowIconSelect] = useState(false)
  const [iconBorder, setIconBorder] = useState('')
  const [errorList, setErrorList] = useState([])
  const [courseOptions, setCourseOptions] = useState(null)
  const [instructorOptions, setInstructorOptions] = useState([])
  const [locations, setLocations] = useState(null)
  const [values, setValues] = useState(initialValues)
  const [activities, setActivities] = useState([])
  const [changePrice, setChangePrice] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activitiesToDelete, setActivitiesToDelete] = useState([])
  const [pricesToEdit, setPricesToEdit] = useState([])
  const [pricesToDelete, setPricesToDelete] = useState([])
  const uID = useSelector((state) => state.login.user._id)

  useEffect(() => {
    async function handleAsync() {
      let locations = await GetLocations()
      let preMadeCourses = await getCourses()

      let filteredPreMade = preMadeCourses.data.filter((x) =>
        x.categories.includes('membership')
      )

      setCourseOptions(filteredPreMade)
      setLocations(locations.data)
    }
    if (locations === null) {
      handleAsync()
    }
  }, [])

  useEffect(() => {
    async function handleAsync() {
      let resp = await getSubscriptionActivities({
        params: { uID: uID, activities: subscription.activities },
      })
      if (resp.success) {
        setActivities(resp.data)
      } else {
        alert('There was a problem retrieving activities for this subscription')
      }
    }
    if (isEdit) {
      handleAsync()
    }
  }, [])

  useEffect(() => {
    async function handleAsync() {
      let resp = await getInstructors({ params: { uID: uID } })
      if (resp.success) {
        setInstructorOptions(resp.data.instructors)
      } else {
        alert(resp)
      }
    }
    handleAsync()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (name === 'requiresInvite' || name === 'active') {
      let tmpBool
      value === 'true' ? (tmpBool = false) : (tmpBool = true)

      setValues({ ...values, [name]: tmpBool })
    } else if (name === 'price' && isEdit) {
      setChangePrice(true)
      setValues({
        ...values,
        [name]: value,
      })
    } else if (name === 'altPrice') {
      let tmpAltPrices = [...values.altPrices]
      let tmpAltPrice = { ...tmpAltPrices[e.target.id] }
      if (isEdit && tmpAltPrice.id !== 'new') {
        const tmpIndex = pricesToEdit.findIndex(
          (elm) => elm.id === tmpAltPrice.id
        )
        if (tmpIndex === -1) {
          let tmp = [...pricesToEdit]
          tmp.push(tmpAltPrice)
          setPricesToEdit(tmp)
        } else {
          let tmp = [...pricesToEdit]
          tmp[tmpIndex] = tmpAltPrice
          setPricesToEdit(tmp)
        }
      }
      tmpAltPrice['price'] = value
      tmpAltPrices[e.target.id] = tmpAltPrice
      setValues({ ...values, ['altPrices']: tmpAltPrices })
    } else {
      setValues({
        ...values,
        [name]: value,
      })
    }
  }

  const handleImageSelect = (value) => {
    setValues((prevState) => ({
      ...prevState,
      icon: value,
    }))
    setShowIconSelect(false)
  }

  const handleAddPrice = () => {
    let tmpAltPrices = [...values.altPrices]
    tmpAltPrices.push({ price: '', period: 'quarter', id: 'new' })
    setValues((prevState) => ({
      ...prevState,
      altPrices: tmpAltPrices,
    }))
  }

  const handleDeletePrice = (index) => {
    let tmpAltPrices = [...values.altPrices]
    if (isEdit) {
      let tmp = [...pricesToDelete]
      tmp.push(tmpAltPrices[index])
      setPricesToDelete(tmp)
    }
    tmpAltPrices.splice(index, 1)
    setValues((prevState) => ({
      ...prevState,
      altPrices: tmpAltPrices,
    }))
  }

  const handlePeriodChange = (index, e) => {
    let tmpAltPrices = [...values.altPrices]
    let tmpAltPrice = tmpAltPrices[index]
    if (tmpAltPrice.id !== 'new') {
      //only add to prices to edit if the alt price already has been created
      const tmpIndex = pricesToEdit.findIndex(
        (elm) => elm.id === tmpAltPrice.id
      )
      if (tmpIndex === -1) {
        pricesToEdit.push(tmpAltPrice)
      } else {
        pricesToEdit[tmpIndex] = tmpAltPrice
      }
    }
    tmpAltPrice['period'] = e.target.value
    setValues((prevState) => ({
      ...prevState,
      altPrices: tmpAltPrices,
    }))
  }

  const handleActivityChange = (type, value, key) => {
    let newArr = [...activities]
    newArr[key] = { ...newArr[key], [type]: value }

    setActivities(newArr)
  }

  const addActivity = () => {
    setActivities([...activities, baseActivity])
  }

  const removeActivity = (index, id) => {
    if (activities.length > 0) {
      let newArr = activities.filter((item, i) => {
        if (index !== i) {
          return item
        }
      })
      setActivities(newArr)
    }
    if (isEdit && id) {
      setActivitiesToDelete([...activitiesToDelete, id])
    }
  }

  const checkData = () => {
    function validURL(url) {
      try {
        new URL(url)
      } catch (e) {
        return false
      }
      return true
    }

    let errTmp = []

    Object.keys(values).forEach((key) => {
      if (values[key] === '' || values[key] === undefined) {
        if (key === 'requiresInvite') {
          setValues((prevState) => ({
            ...prevState,
            requiresInvite: false,
          }))
        }
      }
      //Check to see if URL is legit
      if (key === 'link') {
        let testURL = validURL(values[key])
        if (!testURL) {
          errTmp.push(key)
        }
      }

      if (key === 'altPrices') {
        values[key].forEach((altPrice) => {
          if (altPrice.price === '' || !/^\d+$/.test(altPrice.price)) {
            errTmp.push(altPrice.id)
          }
        })
      }
    })

    activities.forEach((activity, index) => {
      if (activity.courseId === '') {
        errTmp.push('courseId_' + index)
      }
      if (activity.endTime === '' && typeof activity.endTime !== 'object') {
        errTmp.push('endTime' + '_' + index)
      }
      if (activity.startTime === '' && typeof activity.startTime !== 'object') {
        errTmp.push('startTime' + '_' + index)
      }
      if (activity.days.length === 0) {
        errTmp.push('days_' + index)
      }
      if (timeHandler.invalidTimeRange(activity.startTime, activity.endTime)) {
        errTmp.push('rangeError_' + index)
      }
      if (activity.cap === '' || !Number.isInteger(parseInt(activity.cap))) {
        errTmp.push('cap_' + index)
      }
    })
    return errTmp
  }

  const handleSubmit = async () => {
    setLoading(true)
    let errorCheck = checkData()
    if (errorCheck.length > 0) {
      if (errorCheck.includes('icon')) {
        setIconBorder('2px solid red')
      } else {
        setIconBorder('')
      }
      setErrorList(errorCheck)
      setLoading(false)
    } else {
      let resp = null
      if (isEdit) {
        resp = await updateSubscription({
          values: values,
          activities: activities,
          uID: uID,
          subscriptionID: subscription._id,
          changePrice: changePrice,
          pricesToDelete: pricesToDelete,
          pricesToEdit: pricesToEdit,
          activitiesToDelete: activitiesToDelete,
        })
      } else {
        resp = await createSubscription({
          values: values,
          activities: activities,
          uID: uID,
        })
      }
      setLoading(false)
      if (resp.success) {
        setErrorList([])
        setCourseOptions(null)
        setLocations(null)
        setValues(initialValues)
        setActivities([])
        if (isEdit) {
          dispatch(updateSub(resp.data.sub))
          if (resp.data.linkedSubs.length > 0) {
            dispatch(updateLinkedSubs(resp.data.linkedSubs))
          }
        } else {
          dispatch(addSubscription(resp.data.newSub))
          if (resp.data.linkedSubs.length > 0) {
            dispatch(updateLinkedSubs(resp.data.linkedSubs))
          }
        }
        setCurrTab(0)
      } else {
        alert(resp)
      }
    }
  }

  return (
    <>
      <div style={{ margin: '0px auto 100px auto', width: '90%' }}>
        {courseOptions !== null && locations !== null ? (
          <>
            {isEdit ? (
              <Typography variant="h4" align="left">
                Edit Membership
              </Typography>
            ) : (
              <Typography variant="h4" align="left">
                Add Membership
              </Typography>
            )}

            <BlankCard>
              <Grid container spacing={1} justifyContent="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    border: iconBorder,
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowIconSelect(true)
                    }}
                    sx={{
                      color: errorList.includes('icon') ? '#d32f2f' : null,
                    }}
                  >
                    Select an Icon
                  </Button>
                  {values.icon ? (
                    <img
                      src={require(`../../../images/courseIcons/${values.icon}`)}
                      loading="lazy"
                      height="140"
                    />
                  ) : (
                    <div
                      className="missing-icon"
                      style={{
                        backgroundColor: 'lightgrey',
                        width: 140,
                        height: 140,
                        textAlign: 'center',
                        borderRadius: '80px',
                      }}
                    >
                      <ImageNotSupportedIcon
                        sx={{ paddingTop: '25%' }}
                        fontSize="large"
                      />
                      <div>NO ICON SELECTED</div>
                    </div>
                  )}
                </Grid>

                {SUBSCRIPTION_FIELDS.map((field) => {
                  return (
                    <Grid
                      item
                      xs={
                        field.id === 'price' || field.id === 'numOfDays'
                          ? 6
                          : 12
                      }
                      key={field.id}
                    >
                      <TextField
                        required
                        value={values[field.id] ? values[field.id] : ''}
                        name={field.id}
                        margin="dense"
                        id={field.id}
                        label={field.label}
                        type={field.type}
                        error={errorList.includes(field.id)}
                        fullWidth
                        onChange={handleInputChange}
                        variant="standard"
                        InputProps={
                          field.id == 'price'
                            ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }
                            : null
                        }
                        multiline={field.id === 'description' ? true : false}
                      />
                    </Grid>
                  )
                })}

                <Grid item xs={12}>
                  <FormControl fullWidth style={{ margin: '10px' }}>
                    <InputLabel id="select-location">
                      Select Location
                    </InputLabel>
                    <Select
                      labelId="select-location"
                      id="select-location"
                      value={values.locationID}
                      label="Location"
                      error={errorList.includes('locationID')}
                      onChange={handleInputChange}
                      name="locationID"
                    >
                      {locations.map((option) => {
                        return (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {values.altPrices.length > 0 ? (
                  <>
                    {values.altPrices.map((altPrice, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={altPrice.id}
                        >
                          <TextField
                            required
                            value={altPrice.price}
                            name="altPrice"
                            margin="dense"
                            id={index}
                            label="Price"
                            error={errorList.includes(altPrice.id)}
                            onChange={handleInputChange}
                            variant="standard"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormControl style={{ marginLeft: '10px' }}>
                            <InputLabel>Period</InputLabel>
                            <Select
                              value={altPrice.period}
                              onChange={(e) => {
                                handlePeriodChange(index, e)
                              }}
                            >
                              <MenuItem value={'quarter'}>quarter</MenuItem>
                              <MenuItem value={'9 months'}>9 months</MenuItem>
                              <MenuItem value={'year'}>year</MenuItem>
                            </Select>
                          </FormControl>
                          <IconButton
                            color="primary"
                            aria-label="more details link"
                            onClick={() => handleDeletePrice(index)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}

                {values.altPrices.length < 2 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: ' center',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="more details link"
                      onClick={handleAddPrice}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                    <Typography>Add alternate price plan</Typography>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item justifyContent="center" xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.requiresInvite}
                        value={values.requiresInvite}
                        name="requiresInvite"
                        onChange={handleInputChange}
                      />
                    }
                    label="Invite Only"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.active}
                        value={values.active}
                        name="active"
                        onChange={handleInputChange}
                      />
                    }
                    label="Active"
                  />
                </Grid>

                <Grid item xs={12}>
                  {loading === true ? <Typography>Loading</Typography> : <></>}
                </Grid>
              </Grid>
            </BlankCard>
            <Typography variant="h4" align="left">
              Activities
            </Typography>
            {activities.map((item, index) => {
              return (
                <BlankCard>
                  <ActivityRow
                    item={item}
                    index={index}
                    courseOptions={courseOptions}
                    handleChange={handleActivityChange}
                    removeActivity={removeActivity}
                    errorList={errorList}
                    locationID={subscription?.locationID || values.locationID}
                    instructorOptions={instructorOptions}
                  />
                </BlankCard>
              )
            })}
            <Grid item xs={12}>
              <Paper style={{ padding: '10px 10px', marginBottom: '20px' }}>
                <Button
                  onClick={() => {
                    addActivity()
                  }}
                >
                  Add Activity
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {errorList.length > 0 ? (
                <Typography
                  sx={{
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0px 10px',
                  }}
                >
                  Correct Errors
                </Typography>
              ) : (
                <></>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit()
                }}
                disabled={loading}
              >
                {isEdit ? 'Update' : 'Submit'}
              </Button>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {showIconSelect ? (
          <ImageSelector
            handleChange={handleImageSelect}
            handleClose={setShowIconSelect}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default SubscriptionForm
