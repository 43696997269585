import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
} from '@mui/material'
import { changeCourse, getPayment } from '../../../api/admin'
import { GetAllWorkshopsFullInfo, getPrice } from '../../../api/products'
import LoadingComp from '../../util/loading'
import dateHandler from '../../../utils/dateHandler'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ChangeCourse = ({
  course,
  studentID,
  handleClose,
  updateCourses,
  openChangeCourse,
}) => {
  const [products, setProducts] = useState([])
  const [oldPrice, setOldPrice] = useState(null)
  const [priceDifference, setPriceDifference] = useState(0)
  const [productConfirm, setProductConfirm] = useState(false)
  const [confirmedProduct, setConfirmedProduct] = useState(null)
  const [locationSelect, setLocationSelect] = useState(true)
  const [paymentID, setPaymentID] = useState(null)
  const [discount, setDiscount] = useState(0)
  const [chargeID, setChargeID] = useState(null)
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.login.user)
  const parent = useSelector((state) => state.user.selectedUser)
  const locations = useSelector((state) => state.user.locations)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getPrice({
        params: { uID: user._id, productID: course.prodID },
      })
      if (resp.success) {
        setOldPrice(resp.data.price)
      } else {
        alert(
          'There has been an error retrieving price data. Please reload page'
        )
      }
    }
    if (course !== null) {
      handleAsync()
    }
  }, [course])

  useEffect(() => {
    async function handleAsync() {
      let resp = await getPayment({
        params: {
          uID: user._id,
          prodID: course.prodID,
          parentID: parent._id,
          studentID: studentID,
        },
      })
      if (resp.success) {
        if (resp.data !== 'OK') {
          setDiscount(resp.data.discountPercent)
          setPaymentID(resp.data.payment)
          setChargeID(resp.data.transaction)
        }
      } else {
        alert(
          'There has been an error retrieving customer data. Please reload page'
        )
      }
    }
    if (course !== null && !course.credit) {
      handleAsync()
    }
  }, [course])

  const handleLocationSelect = async (location) => {
    setLocationSelect(false)
    let resp = await GetAllWorkshopsFullInfo({
      location: location._id,
    })
    if (resp.success) {
      //show all camps up to two months old
      let tmpDate = new Date()
      tmpDate.setDate(tmpDate.getDate() - 60)
      let activeProducts = resp.data.filter(
        (prod) => new Date(prod.metadata.endDate) > tmpDate
      )
      activeProducts.sort((a, b) => {
        return new Date(b.metadata.startDate) - new Date(a.metadata.startDate)
      })
      setProducts(activeProducts)
    } else {
      alert(
        'There has been an error retrieving product data. Please reload page'
      )
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const calculatePriceDifference = (newProduct) => {
    //calculate old price with discount (if any)
    let oldPriceDiscount = 0
    if (discount === 0) {
      oldPriceDiscount = oldPrice?.unit_amount
    } else {
      oldPriceDiscount =
        oldPrice?.unit_amount - oldPrice?.unit_amount * (discount / 100)
    }

    let difference = newProduct.priceInfo.unit_amount - oldPriceDiscount
    setPriceDifference(difference / 100)
  }

  const changePriceDifference = (difference) => {
    setPriceDifference(difference)
  }

  const handleProductSelect = (product) => {
    setConfirmedProduct(product)
    calculatePriceDifference(product)
    setProductConfirm(true)
  }

  const reset = () => {
    setProducts([])
    setOldPrice(null)
    setDiscount(0)
    setPriceDifference(0)
    setPaymentID(null)
    setProductConfirm(false)
    setConfirmedProduct(null)
    setChargeID(null)
    setLocationSelect(true)
    setLoading(false)
    handleClose()
  }

  const handleBack = () => {
    setProductConfirm(false)
    setConfirmedProduct(null)
  }

  const handleConfirm = async () => {
    setLoading(true)
    let resp = await changeCourse({
      uID: user._id,
      oldProdID: course.prodID,
      newProdID: confirmedProduct.id,
      studentID: studentID,
      user: user,
      charge: chargeID,
      priceDifference: Number(priceDifference) * 100,
      creditID: course.credit?._id,
    })
    if (resp.success) {
      setLoading(false)
      updateCourses(resp.data)
    } else {
      setLoading(false)
      alert(resp.error)
    }
  }

  const formatTime = (startTime, startMinute, endTime, endMinute) => {
    startTime = startTime.replace('0', '')
    endTime = endTime.replace('0', '')
    let time = `${startTime}:${startMinute} - ${endTime}:${endMinute}`
    return time
  }

  return (
    <>
      {course ? (
        <Dialog open={openChangeCourse} onClose={reset}>
          <DialogTitle>
            <Typography variant='h5'>
              {course?.courseName} Course Switch
            </Typography>
          </DialogTitle>
          <DialogContent>
            {productConfirm ? (
              <div>
                {loading ? (
                  <LoadingComp />
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Card>
                        <CardContent>
                          <Typography variant='h6'>
                            {course.courseName}
                          </Typography>
                          <Typography>{course.locationName}</Typography>
                          <Typography>
                            ${oldPrice?.unit_amount / 100}
                          </Typography>
                          <Typography>
                            {formatTime(
                              course.startTime,
                              course.startTimeMinute,
                              course.endTime,
                              course.endTimeMinute
                            )}
                          </Typography>
                          <Typography>
                            {dateHandler.formatDate(course.startDate)} to{' '}
                            {dateHandler.formatDate(course.endDate)}
                          </Typography>
                        </CardContent>
                      </Card>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowForwardIcon />
                      </div>

                      <Card>
                        <CardContent>
                          <Typography variant='h6'>
                            {confirmedProduct.metadata.courseName}
                          </Typography>
                          <Typography>
                            {confirmedProduct.metadata.locationName}
                          </Typography>
                          <Typography>
                            ${confirmedProduct.priceInfo.unit_amount / 100}
                          </Typography>
                          <Typography>
                            {formatTime(
                              confirmedProduct.metadata.startTime,
                              confirmedProduct.metadata.startTimeMinute,
                              confirmedProduct.metadata.endTime,
                              confirmedProduct.metadata.endTimeMinute
                            )}
                          </Typography>
                          <Typography>
                            {dateHandler.formatDate(
                              confirmedProduct.metadata.startDate
                            )}{' '}
                            to{' '}
                            {dateHandler.formatDate(
                              confirmedProduct.metadata.endDate
                            )}
                          </Typography>
                          <Typography>
                            Students: {confirmedProduct.info?.students?.length}/
                            {confirmedProduct.info.cap}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        margin: '15px 0',
                      }}
                    >
                      <Typography variant='h6'>Price differential:</Typography>
                      <TextField
                        variant='outlined'
                        value={priceDifference}
                        onChange={(e) => changePriceDifference(e.target.value)}
                      />
                    </div>
                    <>
                      <>
                        {Number(discount) !== 0 ? (
                          <>
                            <Typography
                              style={{ marginBottom: '5px' }}
                              variant='caption'
                            >
                              A {discount}% discount was attributed to the
                              checkout price.
                            </Typography>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                      {course.credit ? (
                        <Typography
                          style={{
                            marginBottom: '5px',
                            color: 'red',
                          }}
                          variant='subtitle2'
                        >
                          This customer will not be automatically charged or
                          refunded because they paid with cash or a homeschool
                          credit
                        </Typography>
                      ) : (
                        <>
                          {Number(priceDifference) === 0 ? (
                            <>
                              <Typography
                                style={{ marginBottom: '5px' }}
                                variant='caption'
                              >
                                This customer will be not be charged for this
                                switch.
                              </Typography>
                              <br />
                            </>
                          ) : (
                            <>
                              {Number(priceDifference) < 0 &&
                              chargeID === null ? (
                                <>
                                  <Typography
                                    style={{
                                      marginBottom: '5px',
                                      color: 'red',
                                    }}
                                    variant='subtitle2'
                                  >
                                    An automatic refund is not possible for this
                                    transaction. Please use Stripe to refund
                                    customer.
                                  </Typography>
                                </>
                              ) : (
                                <></>
                              )}

                              {isNaN(priceDifference) ? (
                                <Typography
                                  style={{ marginBottom: '5px', color: 'red' }}
                                  variant='subtitle2'
                                >
                                  Price difference must be a number
                                </Typography>
                              ) : (
                                <Typography
                                  style={{ marginBottom: '5px' }}
                                  variant='caption'
                                >
                                  This customer will be
                                  {Number(priceDifference) < 0
                                    ? ' refunded '
                                    : ' charged '}
                                  {formatter.format(Math.abs(priceDifference))}.
                                </Typography>
                              )}

                              <br />
                            </>
                          )}
                        </>
                      )}

                      <>
                        {paymentID ? (
                          <>
                            <Typography variant='caption'>
                              The initial purchase is stored on Stripe with
                              code: {paymentID}
                            </Typography>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                      <Typography
                        style={{ marginTop: '5px' }}
                        variant='caption'
                      >
                        Adjustment payment differential if necessary and confirm
                        the switch.
                      </Typography>
                    </>
                  </>
                )}
              </div>
            ) : (
              <>
                {locationSelect ? (
                  <>
                    {locations.length > 0 ? (
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant='h6'>
                          Select location of new course
                        </Typography>
                        {locations.map((location) => {
                          return (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleLocationSelect(location)}
                              value={location._id}
                              key={location._id}
                            >
                              {location.name}
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <LoadingComp />
                    )}
                  </>
                ) : (
                  <>
                    {products.length > 0 ? (
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant='h6'>Select new course</Typography>
                        {products.map((product) => {
                          let startDate = dateHandler.formatDate(
                            product.metadata.startDate
                          )
                          let endDate = dateHandler.formatDate(
                            product.metadata.endDate
                          )
                          if (course?.prodID !== product.id) {
                            return (
                              <div
                                onClick={() => handleProductSelect(product)}
                                key={product.metadata.name}
                                style={{
                                  border: '1px solid #cdcdcd',
                                  borderRadius: '5px',
                                  marginBottom: '5px',
                                }}
                              >
                                <Typography variant='h6'>
                                  {product.metadata.courseName}
                                </Typography>
                                <Typography>
                                  Price: ${product.priceInfo.unit_amount / 100}
                                </Typography>
                                <Typography>
                                  {formatTime(
                                    product.metadata.startTime,
                                    product.metadata.startTimeMinute,
                                    product.metadata.endTime,
                                    product.metadata.endTimeMinute
                                  )}
                                </Typography>
                                <Typography>
                                  {startDate} to {endDate}
                                </Typography>
                                <Typography>
                                  Students: {product.info?.students?.length}/
                                  {product.info.cap}
                                </Typography>
                              </div>
                            )
                          } else {
                            return <></>
                          }
                        })}
                      </div>
                    ) : (
                      <LoadingComp />
                    )}
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            {productConfirm ? (
              <>
                {loading ? (
                  <></>
                ) : (
                  <div>
                    <Button onClick={handleBack}>Back</Button>
                    <Button
                      onClick={handleConfirm}
                      disabled={
                        isNaN(priceDifference) ||
                        (Number(priceDifference) < 0 && chargeID === null)
                      }
                    >
                      Confirm
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                <Button onClick={reset}>Cancel</Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  )
}

export default ChangeCourse
