import { DateTime } from "luxon";
// Checks if two time strings in military time are a valid range
const invalidTimeRange = (start, end) => {
  let sTime = parseInt(start.replace(":", ""));
  let eTime = parseInt(start.replace(":", ""));
  return sTime > eTime;
};

const getTimeString = ({
  startTime,
  endTime,
  startTimeMinute,
  endTimeMinute,
}) => {
  let x;
  let y;

  if (parseInt(startTime) <= 11) {
    x = `${startTime}${
      startTimeMinute !== "00" && startTimeMinute !== undefined
        ? ":" + startTimeMinute
        : ""
    }AM`;
  } else if (parseInt(startTime) === 12) {
    x = `12${
      startTimeMinute !== "00" && startTimeMinute !== undefined
        ? ":" + startTimeMinute
        : ""
    }PM`;
  } else {
    x = `${parseInt(startTime) - 12}${
      startTimeMinute !== "00" && startTimeMinute !== undefined
        ? ":" + startTimeMinute
        : ""
    }PM`;
  }

  if (parseInt(endTime) <= 11) {
    y = `${endTime}${
      endTimeMinute !== "00" && endTimeMinute !== undefined
        ? ":" + endTimeMinute
        : ""
    }AM`;
  } else if (parseInt(endTime) === 12) {
    y = `12${
      endTimeMinute !== "00" && endTimeMinute !== undefined
        ? ":" + endTimeMinute
        : ""
    }PM`;
  } else {
    y = `${parseInt(endTime) - 12}${
      endTimeMinute !== "00" && endTimeMinute !== undefined
        ? ":" + endTimeMinute
        : ""
    }PM`;
  }
  if (x.startsWith("0")) {
    x = x.substring(1);
  }
  if (y.startsWith("0")) {
    y = y.substring(1);
  }

  return x + " - " + y;
};

const getDateString = (start, end) => {
  let x = start.split("T")[0].split("-");
  let y = end.split("T")[0].split("-");

  x = x[1] + "/" + x[2] + "/" + x[0];
  y = y[1] + "/" + y[2] + "/" + y[0];

  return x + " - " + y;
};

const getDaysString = (days) => {
  if (parseInt(days) === 1) {
    return "1 Day per week";
  } else {
    return days + " Days per week";
  }
};

const convertTime = (time) => {
  time = time.split(":");

  let hour = time[0];
  let minute = time[1];
  if (hour < 12) {
    return hour + ":" + minute + " a.m.";
  } else {
    switch (hour) {
      case "12":
        return "12:" + minute + " p.m.";
      case "13":
        return "1:" + minute + " p.m.";
      case "14":
        return "2:" + minute + " p.m.";
      case "15":
        return "3:" + minute + " p.m.";
      case "16":
        return "4:" + minute + " p.m.";
      case "17":
        return "5:" + minute + " p.m.";
      case "18":
        return "6:" + minute + " p.m.";
      default:
        return "";
    }
  }
};
// Takes a date and sets it's hours and minutes
// Returns DateTime object
const setTime = (date, hours, minutes) => {
  let tmp = DateTime.fromJSDate(new Date(date));
  tmp.set({ hour: hours, minute: minutes, millisecond: 0 });
  return tmp;
};

const timeHandler = {
  invalidTimeRange,
  getTimeString,
  getDateString,
  getDaysString,
  convertTime,
  setTime,
};

export default timeHandler;
