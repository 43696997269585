import React, { useState, useEffect } from 'react'
import { Button, Box, Typography } from '@mui/material/'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import LocationSelector from '../component/locationSelector'
import CourseFilter from '../component/workshop/courseFilters'
import CourseCatalog from '../component/workshop/courseCatalog'
import { useSelector, useDispatch } from 'react-redux'
import {
  setMaster,
  setFilter,
  clearWorkshop,
  setGrid,
  setWorkshops,
  setFilteredWorkshops,
} from '../store/workshopSlice'
import { GetAllWorkshopsFullInfo } from '../api/products'
import GridCatalog from '../component/workshop/gridCatalog'
import '../index.css'
import SecondaryNav from '../component/util/secondaryNav'
import AccountContainer from './account'
import { setUserView, setSelectedUser } from '../store/userSlice'
import LoadingComp from '../component/util/loading'
import ReactGA from 'react-ga4'
import OverviewPage from '../component/overviewPage'
import ReactPixel from 'react-facebook-pixel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AdminCheckOut from '../component/adminViews/adminCheckOut'
import { CurtainsOutlined } from '@mui/icons-material'
import SawyerAlert from '../component/sawyerAlert'
import SawyerWidget from './sawyerWidget'
import { Helmet } from 'react-helmet'
import dateHandler from '../utils/dateHandler'
import { DateTime } from 'luxon'

const WorkShopsContainer = ({ season }) => {
  const navigate = useNavigate()
  const params = useParams()
  //Set title
  let tmpWord = season
  let firstLetter = tmpWord.charAt(0)
  firstLetter = firstLetter.toUpperCase()
  let remainingLetters = tmpWord.slice(1)
  let seasonTitle =
    tmpWord == 'other'
      ? 'Other Activities'
      : firstLetter + remainingLetters + ' ' + 'Camp'
  //Set remaining variables
  const windowLocation = useLocation()
  let permissions = useSelector((state) => state.login?.user?.permissions?.[0])
  const allLocations = useSelector((state) => state.user.locations)
  //Checks if user is admin or parent
  let parent = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })
  const [selectedYear, setSelectedYear] = useState('')
  const [campCode, setCampCode] = useState('')
  const [hasCourses, setHasCourses] = useState(false)
  const [useSawyer, setUseSawyer] = useState(false)
  const [toggleCustomer, setToggleCustomer] = useState('Admin')
  const [seasonHasPassed, setSeasonHasPassed] = useState(false)

  const [purchaseByAdmin, setPurchaseByAdmin] = useState(false)
  useEffect(() => {
    if (windowLocation.state && windowLocation.state?.purchaseByAdmin) {
      setPurchaseByAdmin(windowLocation.state.purchaseByAdmin)
      setToggleCustomer('Customer')
    }
  }, [])

  const TODAY = new Date()

  useEffect(() => {
    //Set selected year to current year
    // SelectedYear used by locationSelector.js
    let tmpYear = TODAY.getFullYear()
    setSelectedYear(tmpYear)
  }, [])

  useEffect(() => {
    //Workaround to account for first year not matching pattern
    if (selectedYear === 2022) {
      setCampCode('wkshp')
    } else {
      let tmpYear = selectedYear
      //Account for holiday camps in Jan, Feb assigned to previous year season
      if (season.includes('holiday') && TODAY.getMonth() <= 2) {
        tmpYear = selectedYear - 1
        // Non admin users should see summer listings for the next year after current year summer season pasts
      } else if (
        season.includes('summer') &&
        TODAY.getMonth() >= 10 &&
        !(permissions >= 3)
      ) {
        tmpYear = selectedYear + 1
      }
      setCampCode(season + '_' + tmpYear)
    }
  }, [selectedYear, season])

  let styles = {
    title: {
      color: '#C80F2D',
    },
    containerHead: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    buttonStyle: {
      margin: ' 0 auto',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D',
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: 'auto',
      width: '60%',
      marginBottom: '5px',
    },
    tempWeb: {},
  }

  const dispatch = useDispatch()
  const workshops = useSelector((state) => state.workshop.workshops)
  const filteredWorkshops = useSelector(
    (state) => state.workshop.filteredWorkshops
  )
  const userView = useSelector((state) => state.user.userView)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const [location, setLocation] = useState(null)
  const locations = useSelector((state) => state.user.locations)
  const [locationOptions, setLocationOptions] = useState(locations)
  const uID = useSelector((state) => state.login.user._id)
  // filters
  const [timeFilter, setTimeFilter] = useState('')
  const [courseFilter, setCourseFilter] = useState('All')
  const [ageFilter, setAgeFilter] = useState('All')
  //List of weeks
  const [dates, setDates] = useState(null)
  const [currWeek, setCurrWeek] = useState(0)
  const [weekFilter, setWeekFilter] = useState('All')
  const [view, setView] = useState(1) // week: 1, grid: 2
  const [isCourseMode, setIsCourseMode] = useState(false)
  const [currTab, setCurrTab] = useState(0)
  const [loading, setLoading] = useState(false)
  /* loading is used when a selected workshop's location info has
  finished loading and when a page is navigating to a url param
  Associated with LoadingComp in CatalogToggle and all TABS */

  useEffect(() => {
    let mounted = true
    if (defaultRegion._id) {
      let regionLocations = locations.filter(
        (loc) => loc.region === defaultRegion._id
      )
      setLocationOptions(regionLocations)
    }
    return () => (mounted = false)
  }, [defaultRegion])

  const handleReset = () => {
    setUseSawyer(false)
    setHasCourses(false)
    setLoading(true)
    // setCurrTab(0)
    // setLocation(null)
    setTimeFilter('')
    setCourseFilter('All')
    setAgeFilter('All')
    setWeekFilter('All')
    dispatch(setMaster({}))
    dispatch(setFilter({}))
  }

  const handleStudent = (data) => {
    dispatch(setSelectedUser(data.parent))
    dispatch(setUserView(1))
  }

  const userBack = () => {
    dispatch(setUserView(0))
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    if (view !== 2) {
      styles.tempWeb = {
        maxWidth: '400px',
        margin: '0 auto',
        textAlign: 'center',
        height: '80vh',
        paddingTop: '10vh',
      }
    } else {
      styles.tempWeb = {}
    }
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/workshop_home',
      title: 'Workshop Home',
    })
    ReactPixel.pageView()
    //resets page when type of camp view is changed
    dispatch(clearWorkshop())
    handleReset()
    setCurrTab(0)
    setLocation(null)
  }, [season])

  useEffect(() => {
    //checks if the season has passed
    if (
      locations.filter((loc) => loc.workshopSeasons.includes(season)).length ===
      0
    ) {
      setSeasonHasPassed(true)
    } else {
      setSeasonHasPassed(false)
    }
  }, [season, locations])

  //TO DO: figure out if this is necessary to include here.
  useEffect(() => {
    async function handleAsync() {
      let productsData = await GetAllWorkshopsFullInfo()
      let tmp = productsData.data
      // setHasCourses(tmp.length > 0)
      dispatch(setWorkshops(tmp))
    }
    if (workshops?.length == 0) {
      handleAsync()
    }
  }, [])

  let sawyerLocations = [
    '65722a6d8bf95898275826d0', //Westlake Village prod ID
    '65e2457efd507f230ad37dda', //Westlake Village dev ID
    '65d645805641fd52171b1a7e', //Oake Park prod ID
  ]

  //Retrieve workshops
  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      if (
        !location ||
        location.name?.toLowerCase().replaceAll(' ', '-') !==
          params.locationParam ||
        campCode.split('_')[1] !== selectedYear
      ) {
        handleReset()
        let tmpSelectedLocation = allLocations.filter((obj) => {
          if (params.locationParam.includes('-')) {
            return (
              obj.name.toLowerCase().replaceAll(' ', '-') ==
              params.locationParam
            )
          } else {
            return obj.name.toLowerCase() == params.locationParam.toLowerCase()
          }
        })[0]
        setLocation(tmpSelectedLocation)
        if (sawyerLocations.includes(tmpSelectedLocation._id)) {
          setUseSawyer(true)
          setCurrTab(1)
          setLoading(false)
        } else {
          let startTime = new Date().getTime()
          let yearStart = DateTime.local(
            selectedYear,
            1,
            1,
            0,
            0
          ).toUnixInteger()
          let productsData = await GetAllWorkshopsFullInfo({
            location: tmpSelectedLocation._id,
            season: campCode,
            date: yearStart, //Workshops created after this date
          })
          if (productsData.success) {
            let endTime = new Date().getTime()
            console.log(
              'Total Load Time: ',
              endTime - startTime,
              ' milliseconds'
            )
            let tmp = productsData.data

            setHasCourses(tmp.length > 0)
            //TO DO: Change the name of this, the workshops are not filtered here.
            dispatch(setFilteredWorkshops(tmp))
            setCurrTab(1)
            setLoading(false)
          } else {
            alert('Error loading workshops.')
          }
        }
      }
    }
    if (allLocations.length > 0) {
      if (params.locationParam && mounted && campCode !== '') {
        handleAsync()
      } else {
        setCurrTab(0)
        setLoading(false)
      }
    }
    return () => (mounted = false)
  }, [params, allLocations, campCode])

  //Sort workshops
  useEffect(() => {
    let buckets = {}
    let tmpWorkshops = [...filteredWorkshops]
    //TO DO update naming here. Master list is filtered, "filterWorkshops" is not.
    // sort for order
    const sortedProds = tmpWorkshops.sort(
      (a, b) =>
        dateHandler.getDateTimeObj(a.metadata.startDate) -
        dateHandler.getDateTimeObj(b.metadata.startDate)
    )
    sortedProds.forEach((tmp) => {
      let item = { ...tmp }
      let bucketName = `${dateHandler.formatDate(
        item.metadata.startDate
      )} - ${dateHandler.formatDate(item.metadata.endDate)}`
      if (buckets[bucketName]) {
        // item exists so just push item
        buckets[bucketName].push(item)
      } else {
        // create bucket and push item
        buckets[bucketName] = []
        buckets[bucketName].push(item)
      }
    })
    // testing
    let names = Object.keys(buckets)
    let courseBucket = {}

    // Iterate thru each week (names) then iterate thru each course for that week and create a course object in courseBucket
    // Populates buckets object with unique courses as empty arrays
    names.forEach((name) => {
      buckets[name].forEach((item) => {
        item.tValue = name
        if (courseBucket[item.metadata.course]) {
        } else {
          courseBucket[item.metadata.course] = []
        }
      })
    })
    // Iterate thru each unique course, and push an object with hValue to the array (placeholders)
    Object.keys(courseBucket).forEach((item) => {
      names.forEach((name) => {
        courseBucket[item].push({ hValue: name })
      })
    })
    // Iterate thru each unique course, then iterate thru each unique course's object (bucket)
    Object.keys(courseBucket).forEach((course) => {
      courseBucket[course].forEach((item, index) => {
        let match = buckets[item.hValue].filter(
          (x) => x.metadata.course === course
        )
        if (match.length > 1) {
          //AM and PM course this week, sort by startTime
          match.sort(
            (a, b) =>
              parseInt(a.metadata.startTime) - parseInt(b.metadata.startTime)
          )
        } else if (match.length == 1) {
          //Only one course instance, add placeholder depending on AM/PM time slot
          if (parseInt(match[0].metadata.startTime) < 12) {
            match.push(null)
          } else {
            match.unshift(null)
          }
        }
        if (match.length !== 0) {
          //If match is found, write to unique course object - replaces hValue placeholder
          courseBucket[course][index] = match
        }
      })
    })

    setDates(Object.keys(buckets))
    dispatch(setMaster(buckets))
    dispatch(setGrid({ bucket: courseBucket, names: names }))
  }, [filteredWorkshops])

  const filterData = async (list) => {
    let keys = Object.keys(list)
    let tmp = {}
    //TO DO: rewrite as switch case
    if (weekFilter == 'Past') {
      keys = Object.keys(list)
    } else if (weekFilter == 'All') {
      keys = Object.keys(list).slice(currWeek)
    } else {
      keys = [weekFilter]
    }

    keys.forEach((key) => {
      let hasKeys = false
      // age check
      if (ageFilter !== '') {
        tmp[key] = list[key].filter((item) =>
          ageFilter === 'All'
            ? true
            : item.info.ageMin <= ageFilter && item.info.ageMax >= ageFilter
        )
        hasKeys = true
      }
      // time check
      if (timeFilter !== '') {
        if (hasKeys) {
          tmp[key] = tmp[key].filter((item) =>
            timeFilter === 'am'
              ? item.metadata.startTime < 12
              : item.metadata.startTime > 12
          )
        } else {
          tmp[key] = list[key].filter((item) =>
            timeFilter === 'am'
              ? item.metadata.startTime < 12
              : item.metadata.startTime > 12
          )
          hasKeys = true
        }
      }
      // course check
      if (courseFilter !== '') {
        if (hasKeys) {
          tmp[key] = tmp[key].filter((item) =>
            courseFilter === 'All'
              ? true
              : item.metadata.course === courseFilter
          )
        } else {
          tmp[key] = list[key].filter((item) =>
            courseFilter === 'All'
              ? true
              : item.metadata.course === courseFilter
          )
          hasKeys = true
        }
      }
    })

    setIsCourseMode(false)
    // clean up empty keys
    dispatch(setFilter(tmp))
  }

  let ml = useSelector((state) => state.workshop.masterList)

  useEffect(() => {
    if (ageFilter == 'All' && weekFilter == 'All' && courseFilter == 'All') {
      dispatch(setFilter({}))
    } else {
      filterData(ml)
    }
  }, [ageFilter, timeFilter, weekFilter, courseFilter])

  //Set view to present week
  useEffect(() => {
    if (dates !== null) {
      function getMonday(d) {
        d = new Date(d)
        let day = d.getDay()
        let diff = d.getDate() - day + (day == 6 ? 8 : 1) //adjust so it gets the next week starting Saturday
        return new Date(d.setDate(diff))
      }

      let beginningOfWeek = new moment(getMonday(new Date()))

      const dateIndex = dates.findIndex((element) => {
        if (
          new moment(element.split('-')[0]) > beginningOfWeek ||
          element.includes(beginningOfWeek.format('MM/DD/YYYY'))
        ) {
          return true
        }
      })
      if (dateIndex !== -1) {
        setCurrWeek(dateIndex)
      }
    }
  }, [dates])

  const handleFilterChange = (type, value) => {
    let filterMap = {
      time: () => setTimeFilter(value),
      course: () => setCourseFilter(value),
      age: () => setAgeFilter(value),
      week: () => setWeekFilter(value),
    }
    filterMap[type]()
  }

  // takes either postive or negative 1
  const handleWeek = (value) => {
    // safety check
    if (value === -1 && currWeek !== 0) {
      let tmp = currWeek
      setCurrWeek((tmp += value))
    }
    if (value === 1 && currWeek < dates.length - 1) {
      let tmp = currWeek
      setCurrWeek((tmp += value))
    }
  }

  const handleView = (viewType) => {
    setView(parseInt(viewType))
  }

  const handlePast = (date) => {
    let today = new Date()
    let startDate = new Date(date)
    //allow 2 days after start to sign up
    startDate.setDate(startDate.getDate() + 2)
    return today - startDate > 0
  }

  const handleSelect = (location) => {
    ReactGA.event({
      category: 'button',
      action: `Select ${location.name}`,
    })
    setCurrTab(1)
    //TO DO: create custom hook for this location navigation and apply it to subscription > selectLocation.js
    let keys = windowLocation.pathname
      .slice(1, windowLocation.pathname.length)
      .split('/')
    let path = '/' + keys[0]
    let urlParam = location.name.toLowerCase().replaceAll(' ', '-')
    path += '/' + urlParam
    navigate(`${path}`, { replace: true })
  }
  {
    const CatalogToggle = () => {
      return (
        <>
          {!loading ? (
            useSawyer ? (
              <>
                <SawyerAlert />
                <SawyerWidget locationId={location._id} />
              </>
            ) : !hasCourses ? (
              <h1>
                {permissions < 3 || toggleCustomer !== 'Admin'
                  ? 'Schedule coming soon.'
                  : 'No workshops have been created for this season and location'}
              </h1>
            ) : (
              <>
                {/* hard code for manhattan heights community center summer camps 2023 */}
                {location?._id === '63d91b8b130490ef8293c88d' ? ( //dev '63e9541c6409fe45e75c1095'
                  <>
                    <Box>
                      <Typography sx={{ margin: '30px auto 15px auto' }}>
                        {location.info}
                      </Typography>
                      <Button
                        variant='contained'
                        onClick={() =>
                          window.open(
                            'https://www.manhattanbeach.gov/departments/parks-and-recreation/special-events/summer-camp-expo/summer-camps',
                            '_blank'
                          )
                        }
                      >
                        Enroll in Summer Camp
                      </Button>
                    </Box>
                  </>
                ) : (
                  // end hardcode
                  <Box
                    sx={{
                      display: 'flex',
                      width: { xs: '95%', sm: '85%' },
                      margin: '20px auto',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <CourseFilter
                      courseFilter={courseFilter}
                      handleChange={handleFilterChange}
                      time={timeFilter}
                      handleWeek={handleWeek}
                      week={currWeek}
                      dates={dates}
                      location={location}
                      isCourseMode={isCourseMode}
                      ageFilter={ageFilter}
                      weekFilter={weekFilter}
                      handleView={handleView}
                      view={view}
                    />
                    {view === 1 ? (
                      <CourseCatalog
                        week={currWeek}
                        dates={dates}
                        uID={uID}
                        toggleCustomer={
                          permissions >= 3
                            ? toggleCustomer === 'Customer'
                            : true
                        }
                      />
                    ) : (
                      <></>
                    )}
                    {view === 2 ? (
                      <GridCatalog
                        week={currWeek}
                        handlePast={handlePast}
                        style={{ margin: '0px 10%' }}
                        location={location}
                        isAdmin={
                          permissions >= 3 ? toggleCustomer === 'Admin' : false
                        }
                        uID={uID}
                        handleStudent={handleStudent}
                        permissions={permissions}
                        toggleCustomer={
                          permissions >= 3
                            ? toggleCustomer === 'Customer'
                            : true
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </>
            )
          ) : (
            <LoadingComp />
          )}
        </>
      )
    }

    // use \n to create line breaks. see description() in overviewPage.js to see how strings are manipulated
    const DESCRIPTIONS = {
      default:
        'Rolling Robots STEM and Robotics Camps are designed to encourage open creativity and deep exploration. Each camp session runs one week Monday thru Friday either in the morning (9am - 12pm) or afternoon (1pm - 4pm).',
      summer:
        'Rolling Robots STEM and Robotics Camps are designed to encourage open creativity and deep exploration featuring small camp size, feasible weekly schedule, hands-on and project-based building, coding and design camps for kids aged 5 to 15.\nEach camp session runs one week Monday thru Friday in the morning (AM) (9am - 12pm) and afternoon (PM) (1pm - 4pm).  When campers are taking both AM & PM camps in the same week, we will provide a complimentary lunch time.',
      holiday:
        'Rolling Robots STEM and Robotics Camps are designed to encourage open creativity and deep exploration. Each holiday camp session runs one week Tuesday thru Friday either in the morning (9am - 12pm) or afternoon (1pm - 4pm).',
      other:
        'Join us for a variety of engaging events at Rolling Robots! Dive into the world of STEM with us and discover the exciting possibilities. Check our schedule for upcoming events, each uniquely designed to spark creativity and learning.',
    }

    const getDescription = (campCode) => {
      let season = campCode.split('_')[0]
      if (DESCRIPTIONS[season]) {
        return DESCRIPTIONS[season]
      } else {
        return DESCRIPTIONS['default']
      }
    }

    const TABS = [
      {
        label: 'Overview',
        content: (
          <OverviewPage
            title='Start the Path to Tech Genius'
            description={getDescription(campCode)}
            toggleCustomer={permissions >= 3 ? toggleCustomer : false}
            season={campCode}
          >
            {seasonHasPassed &&
            (permissions < 3 || toggleCustomer !== 'Admin') ? (
              <>
                <Typography variant='h2' textAlign='center'>
                  {season === 'other' ? (
                    <>
                      There are currently no upcoming events scheduled. <br />{' '}
                      Please check back another time.
                    </>
                  ) : (
                    <>
                      This camp season has concluded for the year. <br /> Stay
                      tuned for the upcoming season's schedule!
                    </>
                  )}
                </Typography>
                <Typography textAlign='center'>
                  Or take a look at our <a href='/memberships'>memberships.</a>
                </Typography>
              </>
            ) : (
              <LocationSelector
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                locations={locations}
                onSelect={handleSelect}
                season={season}
                permissions={permissions}
                toggleCustomer={permissions >= 3 ? toggleCustomer : false}
              />
            )}
          </OverviewPage>
        ),
      },
      {
        label: location?.name || 'Select Camp',
        content: <CatalogToggle />,
      },
    ]

    return (
      <>
        <Helmet>
          <title>Camp Finder | Rolling Robots</title>
          <meta
            name='description'
            content='Rolling Robots has STEM camp locations throughout Southern California including North Los Angeles, South Bay, Orange County, Ventura County, and West Los Angeles.'
          />
        </Helmet>
        {userView === 0 ? (
          <>
            {permissions >= 3 ? (
              <SecondaryNav
                title={seasonTitle}
                tabs={TABS}
                currTab={currTab}
                setCurrTab={setCurrTab}
                isDynamic={true}
                setToggleCustomer={
                  purchaseByAdmin ? undefined : setToggleCustomer
                } //if purchasing as an admin, do not show customer toggle
                toggleCustomer={toggleCustomer}
              />
            ) : (
              <SecondaryNav
                title={seasonTitle}
                tabs={TABS}
                currTab={currTab}
                setCurrTab={setCurrTab}
                isDynamic={true}
              />
            )}
            {purchaseByAdmin ? (
              <AdminCheckOut parent={parent} productType={'workshop'} />
            ) : null}
            {loading ? (
              <>
                <LoadingComp />
              </>
            ) : (
              <>{TABS[currTab].content}</>
            )}
          </>
        ) : (
          <></>
        )}

        {userView === 1 ? (
          <>
            <AccountContainer back={userBack} />
          </>
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default WorkShopsContainer
