import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import cards from '../util/cards'
import LaunchIcon from '@mui/icons-material/Launch'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import dateHandler from '../../utils/dateHandler'
import useNavToAccountView from '../../utils/navToAccountView'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

const AdminOrderTable = ({
  data,
  count,
  handlePageChange,
  pagination,
  pageOrders,
}) => {
  const [disableBack, setDisableBack] = useState(true)
  const handleNavToAccountView = useNavToAccountView()
  let user = useSelector((state) => state.login.user)
  const BlankCard = cards.blankCard

  const COLUMNS = [
    'Order Date',
    'Customer Name',
    'Email',
    'ID',
    'Product Type',
    'Total',
    'Discount',
    'Refund',
    // 'Item(s)',
    // 'Activity Date',
    'Location',
  ]
  let COLUMN_MAPPING = {
    'Order Date': 'created',
    'Customer Name': 'customer',
    'Product Type': 'type',
    Total: 'amount',
    Discount: 'discount',
    Refund: 'refund',
    // 'Item(s)': 'items',
    // 'Activity Date': 'activityDate',
    Location: 'location',
    Email: 'email',
    ID: 'paymentID',
  }

  const isAdmin = true

  const styles = {
    grid: {
      display: 'inline-grid',
      gridTemplateColumns: isAdmin
        ? `repeat(${COLUMNS.length}, minmax(200px, 400px))`
        : `200px 100px repeat(4, minmax(200px, 400px))`,
      borderBottom: '1px solid #e3e3e3',
      borderCollapse: 'separate',
      padding: '10px 0px',
    },
  }

  useEffect(() => {
    if (count > 0) {
      setDisableBack(false)
    } else {
      setDisableBack(true)
    }
  }, [count])

  const getDate = (date) => {
    let convertedDate = dateHandler.getDateTimeObj(date)
    //Check if the date is over 30 days ago (Expired)
    let today = DateTime.now()
    let thirtyDaysAgo = today.minus({ days: 30 })
    let expired = false
    if (convertedDate < thirtyDaysAgo) {
      expired = true
    }
    convertedDate = dateHandler.formatDate(convertedDate)
    return { number: convertedDate, expired: expired }
  }

  return (
    <>
      <IconButton
        aria-label="orders-back"
        size="small"
        disabled={pagination == 10}
        onClick={() => handlePageChange('back')}
      >
        {<ArrowBackIcon />}
      </IconButton>
      <IconButton
        aria-label="orders-forward"
        size="small"
        onClick={() => handlePageChange('forward')}
      >
        {<ArrowForwardIcon />}
      </IconButton>
      <BlankCard style={{ overflowX: 'auto' }}>
        <Box id="table-header" sx={{ ...styles.grid }}>
          {Object.keys(COLUMN_MAPPING).map((col) => {
            return (
              <Typography variant="h6" key={col}>
                {col}
              </Typography>
            )
          })}
        </Box>
        {pageOrders.length > 0 ? (
          pageOrders.map((order, index) => {
            return (
              <Box id={index} sx={{ ...styles.grid }}>
                {Object.values(COLUMN_MAPPING).map((col, index) => {
                  if (order[col]) {
                    if (col == 'paymentID') {
                      return (
                        <Typography key={`${col}-${index}`}>
                          <a href={order.receipt} target="_blank">
                            {order[col]}
                          </a>
                        </Typography>
                      )
                    } else if (col == 'customer' && order.customer) {
                      return (
                        <Typography
                          key={`${col}-${index}`}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                          onClick={() =>
                            handleNavToAccountView(
                              user._id,
                              order.customer?._id
                            )
                          }
                        >
                          {order.customer?.info?.name.fName}{' '}
                          {order.customer?.info?.name.lName}
                        </Typography>
                      )
                    } else if (col == 'created') {
                      let date = getDate(order[col])
                      return (
                        <Typography key={`${col}-${index}`}>
                          {date.number}
                        </Typography>
                      ) //Expired handling
                    } else {
                      return (
                        <Typography key={`${col}-${index}`}>
                          {order[col]}
                        </Typography>
                      )
                    }
                  } else {
                    return <Typography></Typography>
                  }
                })}
              </Box>
            )
          })
        ) : (
          <></>
        )}
      </BlankCard>
    </>
  )
  return (
    <>
      <IconButton
        aria-label="orders-back"
        size="small"
        disabled={disableBack}
        onClick={() => handlePageChange('back')}
      >
        {<ArrowBackIcon />}
      </IconButton>
      <IconButton
        aria-label="orders-forward"
        size="small"
        onClick={() => handlePageChange('forward')}
      >
        {<ArrowForwardIcon />}
      </IconButton>
      <Box sx={{ padding: '0 5%', marginTop: '10px' }}>
        {data == null ? (
          <></>
        ) : (
          <BlankCard style={{ overflowX: 'auto' }}>
            <Box id="table-header" sx={{ ...styles.grid }}>
              {COLUMNS.map((col) => {
                return (
                  <Typography variant="h6" key={col}>
                    {col}
                  </Typography>
                )
              })}
            </Box>
            {data.map((row, index) => {
              let orderDate = ''
              if (row.type === 'credit') {
                let tmpDate = new Date(row.created)
                tmpDate = dateHandler.getMonthDayYear(tmpDate)
                orderDate = tmpDate
              } else {
                orderDate = getDate(row.created)
              }

              return (
                <React.Fragment key={index}>
                  <Box id={index} sx={{ ...styles.grid }}>
                    {row.type === 'credit' ? (
                      <Typography variant="body2">{orderDate}</Typography>
                    ) : (
                      <Typography variant="body2">
                        {orderDate.number}
                        {!orderDate.expired ? (
                          <IconButton
                            aria-label="more details link"
                            size="small"
                            onClick={() => window.open(row.receipt)}
                          >
                            <LaunchIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    )}
                    <Typography>{row.customer.name}</Typography>
                    <Typography>{row.customer.email}</Typography>
                    <Typography>{row.paymentID}</Typography>
                    <Typography>{row.product}</Typography>
                    <Typography>${row.amount?.toFixed(2)}</Typography>
                    <Typography>${row.discount?.toFixed(2)}</Typography>
                    <Typography>${row.refund?.toFixed(2)}</Typography>

                    {row.lineItems.map((item, index) => {
                      let activityDate
                      if (row.product === 'membership') {
                        activityDate = orderDate.number
                      } else {
                        let startDate = item.startDate
                        let endDate = item.endDate
                        if (startDate) {
                          startDate = new Date(startDate)
                          startDate = dateHandler.getMonthDayYear(startDate)
                        } else {
                          startDate = 'invalid date'
                        }
                        if (endDate) {
                          endDate = new Date(endDate)
                          endDate = dateHandler.getMonthDayYear(endDate)
                        } else {
                          endDate = 'invalid date'
                        }
                        activityDate = `${startDate} - ${endDate}`
                      }

                      return (
                        <React.Fragment key={index}>
                          <Typography style={{ gridColumnStart: 9 }}>
                            {item.courseName || item.cn}
                          </Typography>
                          <Typography>{activityDate}</Typography>
                          <Typography>{item.loc || item.location}</Typography>
                        </React.Fragment>
                      )
                    })}
                  </Box>
                </React.Fragment>
              )
            })}
          </BlankCard>
        )}
      </Box>
    </>
  )
}

export default AdminOrderTable
