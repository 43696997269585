import moment from 'moment'
import { DateTime } from 'luxon'

const getDayName = (dateStr, locale) => {
  var date = new Date(dateStr)
  return date.toLocaleDateString(locale, { weekday: 'long' })
}

const getAge = (birthDate) => {
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)
}

const isTooOld = (date) => {
  let age = getAge(date)
  if (age >= 18) {
    return true
  } else {
    return false
  }
}

const invalidDate = (date) => {
  var timestamp = Date.parse(date)
  return isNaN(timestamp)
}

const toUSDate = (date) => {
  let c = date.split('T')[0]
  let b = c.split('-')
  let a = b[1] + '-' + b[2] + '-' + b[0]
  return a
}

const addDays = (date, days) => {
  var tmp = new Date(date)
  tmp.setDate(tmp.getDate() + days)
  return tmp
}

const addHours = (time, hours) => {
  var tmp = new Date(time)
  tmp.setHours(tmp.getHours() + hours)
  return tmp
}

const shortenDateSpan = (date) => {
  let start = date.split('-')[0]
  let end = date.split('-')[1]

  start = start.split('/')
  end = end.split('/')

  start = start[0] + '/' + start[1]
  end = end[0] + '/' + end[1]

  return start + ' - ' + end
}

//Handles two date formats to get correct start and end date and returns a STRING
//To get an object, use getDateTimeObj instead
const formatDate = (date) => {
  return getDateTimeObj(date).toLocaleString()
}

const toStandardTime = (time) => {
  let timeArr = time.split(':')
  let hour = parseInt(timeArr[0])
  let minute = parseInt(timeArr[1])

  if (minute === 0) {
    minute = '00'
  }

  if (hour < 12) {
    return hour + ':' + minute + ' am'
  } else {
    if (hour !== 12) {
      hour = hour - 12
    }
    return hour + ':' + minute + ' pm'
  }
}

const toAbbreviatedTime = (time) => {
  let timeArr = time.split(':')
  let hour = parseInt(timeArr[0])

  if (hour < 12) {
    return hour + ' am'
  } else {
    if (hour !== 12) {
      hour = hour - 12
    }
    return hour + ' pm'
  }
}

const isFourDays = (startDate, endDate) => {
  // Check if a camp is less than 5 days or if it falls on the upcoming July 4th
  let start = new Date(startDate)
  let end = new Date(endDate)

  const currentYear = new Date().getFullYear()
  const julyFourth = new Date(currentYear, 6, 4)
  if (new Date() > julyFourth) {
    // If July 4th of this year has already passed, get it for the next year
    julyFourth.setFullYear(currentYear + 1)
  }

  let days = new Date(endDate).getDay() - new Date(startDate).getDay() + 1
  if (days < 5 || (start <= julyFourth && end >= julyFourth)) {
    return true
  } else {
    return false
  }
}
// Accepts dates as ISO or UNIX and returns string with weekday
const formatWithWeekday = (date) => {
  let dateObj
  if (DateTime.fromISO(date).isValid) {
    dateObj = DateTime.fromISO(date)
  } else {
    dateObj = DateTime.fromSeconds(parseInt(date))
  }
  return dateObj.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
}

const getShortenedDay = (day) => {
  if (day === 0) {
    return 'Sun'
  } else if (day === 1) {
    return 'M'
  } else if (day === 2) {
    return 'T'
  } else if (day === 3) {
    return 'W'
  } else if (day === 4) {
    return 'Thr'
  } else if (day === 5) {
    return 'F'
  } else if (day === 6) {
    return 'Sat'
  } else {
    return ''
  }
}

const getLengthenedDay = (day) => {
  switch (day) {
    case 'Sat':
      return 'Saturday'
    case 'Sun':
      return 'Sunday'
    case 'M':
      return 'Monday'
    case 'T':
      return 'Tuesday'
    case 'W':
      return 'Wednesday'
    case 'Thr':
      return 'Thursday'
    case 'F':
      return 'Friday'
    default:
      return ''
  }
}

const getMonthDayYear = (date) => {
  date =
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  return date
}

const isInThePast = (date) => {
  let today = new Date()
  today.setHours(0, 0, 0, 0)
  let dateObj = formatDate(date)
  let tmp = dateObj - today
  return tmp < 0
}

const isOverFiveDaysFromNow = (date) => {
  let today = new Date()
  today.setHours(0, 0, 0, 0)
  let dateObj = formatDate(date)

  let dateDiff = Math.round((dateObj - today) / (1000 * 60 * 60 * 24))
  if (dateDiff >= 5) {
    return true
  } else {
    return false
  }
}

const isToday = (date) => {
  let today = new Date()
  today.setHours(0, 0, 0, 0)

  let dateObj = new Date()
  let dateArr = date.split('-')
  dateObj.setFullYear(dateArr[0])
  dateObj.setMonth(dateArr[1] - 1)
  dateObj.setDate(dateArr[2])
  dateObj.setHours(0, 0, 0, 0)

  if (today.getTime() === dateObj.getTime()) {
    return true
  } else {
    return false
  }
}
// Checks if an array of dates is consecutive
const isConsecutiveDates = (dateArr) => {
  try {
    let consecutive = true
    if (dateArr.length <= 1) {
      return consecutive
    } else {
      let startDate = getDateTimeObj(dateArr[0])
      for (let i = 1; i < dateArr.length && consecutive; i++) {
        // Use Luxon math to calculate consecutive dates to account for transitioning between months (31st to 1st)
        if (
          getDateTimeObj(dateArr[i]).day !== startDate.plus({ days: i }).day
        ) {
          consecutive = false
        }
      }
    }
    return consecutive
  } catch (error) {
    console.log(dateArr)
    console.log(
      'Error confirming consecutive dates. This is likely due to a descrepancy with how dates are saved. Dates will show as consecutive.'
    )
    return true
  }
}

const formatDateList = (dateArr) => {
  return dateArr.map((date, i) => (
    <>
      {i > 0 ? ', ' : null}
      {dateHandler.formatWithWeekday(date)}
    </>
  ))
}

const getConsecutiveDates = (startDate, endDate) => {
  let arr = []
  let diff = new moment(endDate).diff(new moment(startDate), 'days')
  for (let i = 0; i < diff; i++) {
    let date = new moment(startDate).add(i, 'd').format('MMM DD, YYYY')
    arr.push(date)
  }
  arr.push(new moment(endDate).format('MMM DD, YYYY'))
  return arr
}

//Accepts date as ISO or UNIX and returns Date object in PST
const getDateTimeObj = (dateValue) => {
  if (DateTime.fromISO(dateValue).isValid) {
    return DateTime.fromISO(dateValue, {
      zone: 'America/Los_Angeles',
    })
  } else {
    return DateTime.fromSeconds(parseInt(dateValue), {
      zone: 'America/Los_Angeles',
    })
  }
}

const dateHandler = {
  getDayName,
  getAge,
  isTooOld,
  invalidDate,
  toUSDate,
  addDays,
  addHours,
  shortenDateSpan,
  formatDate,
  toStandardTime,
  toAbbreviatedTime,
  isFourDays,
  formatWithWeekday,
  getShortenedDay,
  getMonthDayYear,
  isInThePast,
  isOverFiveDaysFromNow,
  isToday,
  getLengthenedDay,
  isConsecutiveDates,
  formatDateList,
  getConsecutiveDates,
  getDateTimeObj,
}

export default dateHandler
