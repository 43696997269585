import React, { useState, useEffect } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  InputAdornment,
  IconButton,
  Autocomplete,
  Typography,
  Paper,
  Alert,
  Chip,
  OutlinedInput,
  Box,
} from '@mui/material'
import { useSelector } from 'react-redux'
import ModelHandler from '../../../models/modelHandler'
import '../../../css/react-datepicker.css'
import CloseIcon from '@mui/icons-material/Close'
import { getInstructors } from '../../../api/admin'
import MultiDatePicker from 'react-multi-date-picker'
import TimeSelect from '../timeSelect'

const ProductForm = ({
  handleClose,
  handleSave,
  type,
  addWorkshop,
  product,
  setProduct,
}) => {
  const uID = useSelector((state) => state.login.user._id)
  const [error, setError] = useState([])
  const [changed, setChanged] = useState(false)
  let locations = useSelector((state) => state.user.locations)
  let courses = useSelector((state) => state.parent.courses)
  let course = null
  if (!addWorkshop) {
    course = courses.filter((tmpCourse) => tmpCourse._id === product.course)[0]
  }
  const [duplicateError, setDuplicateError] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [instructors, setInstructors] = useState([])

  //check if course age has been updated and alert user that workshop age will be updated on save
  let outdatedAgeData = false
  if (!addWorkshop) {
    if (
      parseInt(course?.age?.min) !== parseInt(product.ageMin) ||
      parseInt(course?.age?.max) !== parseInt(product.ageMax)
    ) {
      outdatedAgeData = true
    }
  }

  const styles = {
    fields: {
      margin: 8,
      date: {
        zIndex: '1000',
      },
    },
    fieldWrapper: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
    },
    fullField: {
      gridColumn: '1 / 3',
    },
    dateHelpText: {
      color: '#d32f2f',
      fontSize: 12,
    },
    tempWeb: {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh',
    },
  }

  useEffect(() => {
    //Get instructor options
    async function handleAsync() {
      let resp = await getInstructors({ params: { uID: uID } })
      if (resp.success) {
        setInstructors(resp.data.instructors)
      } else {
        alert(resp)
      }
    }
    handleAsync()
  }, [])

  const WORKSHOP_TYPES = [
    {
      _id: 'summer',
      name: 'Summer Workshop',
    },
    {
      _id: 'winter',
      name: 'Winter Workshop',
    },
    {
      _id: 'spring',
      name: 'Spring Break Workshop',
    },
    {
      _id: 'holiday',
      name: 'Holiday Workshop',
    },
    { _id: 'other', name: 'Other Workshops' },
  ]

  const PRODUCT_FIELDS = [
    {
      label: 'Workshop',
      key: 'workshop',
      isRequired: true,
      data: WORKSHOP_TYPES,
    },
    {
      label: 'Location',
      key: 'location',
      isRequired: true,
      data: locations,
    },
    {
      label: 'Course',
      key: 'course',
      isRequired: true,
      data: courses,
    },
    { label: 'Select Dates', key: 'selectDates', isRequired: true },
    {
      label: 'Start Time',
      key: 'startTime',
      isRequired: true,
    },
    {
      label: 'End Time',
      key: 'endTime',
      isRequired: true,
    },
    {
      label: 'Capacity',
      key: 'capacity',
      isRequired: true,
    },
    {
      label: 'Price',
      key: 'price',
      isRequired: true,
    },
    { label: 'Instructors:', key: 'instructors' },
  ]

  const handleChange = (type, value) => {
    let tmpProduct = null
    setChanged(true)
    if (type === 'location' || type === 'course') {
      let data = value.split('&BREAK&')
      tmpProduct = { ...product, [type + 'Name']: data[1], [type]: data[0] }
      // TO DO: Put this back in with new date/time handling
      // } else if (type === 'startTime') {
      //   //auto set end time based on start time
      //   let autoEnd = dateHandler.addHours(value, 3)
      //   tmpProduct = { ...product, startTime: value, endTime: autoEnd }
    } else if (type === 'selectDates') {
      let dates = value
      tmpProduct = {
        ...product,
        selectDates: dates,
        startDate: dates[0],
        endDate: dates[dates.length - 1],
      }
    } else {
      tmpProduct = { ...product, [type]: value }
    }
    setProduct(tmpProduct)
  }

  const submit = () => {
    setDisableSave(true)
    let err = ModelHandler.checkProductData(product, type)
    if (err.length === 0) {
      if (type === 'edit') {
        handleSave(type === 'edit', course)
      } else {
        if ((type === 'duplicate' && changed) || type === 'save') {
          setError([])
          setDuplicateError(false)
          handleSave(type === 'edit')
        } else {
          setDuplicateError(true)
        }
      }
    } else {
      setDisableSave(false)
      setError(err)
    }
  }

  const standardField = (item) => {
    return (
      <>
        <TextField
          required={item.isRequired}
          value={item.key === 'capacity' ? product['cap'] : product[item.key]}
          id={item.key}
          key={item.key}
          error={error.includes('cap') || error.includes(item.key)}
          label={item.label}
          type={item.key === 'capacity' ? 'number' : null}
          InputProps={
            item.key === 'price'
              ? {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              : null
          }
          onChange={(e) => {
            handleChange(
              item.key === 'capacity' ? 'cap' : item.key,
              e.target.value
            )
          }}
        />
      </>
    )
  }
  const timeField = (item) => {
    return (
      <>
        <TimeSelect
          startTimeString={product.startTime}
          endTimeString={product.endTime}
          errorValue={error.includes('timeRangeError')}
          handleChange={handleChange}
        />
        <div style={styles.dateHelpText}>
          {error.includes('timeRangeError') ? 'Before Start time' : null}
        </div>
      </>
    )
  }

  const SelectDateInput = ({ onFocus, item, error }) => {
    return (
      <Box>
        <Button
          variant="outlined"
          onClick={() => onFocus()}
          id={item.key}
          sx={{
            padding: '9px 39px',
            width: '100%',
            borderColor: error.length > 0 ? 'red' : '#5c5c5c',
          }}
        >
          <Box>
            {product.selectDates?.length > 0
              ? product.selectDates.map((value, i) => {
                  return <Chip label={value.toString()} key={item.key + i} />
                })
              : 'Select Dates'}
          </Box>
        </Button>
        <div style={styles.dateHelpText}>
          {error.includes('inThePast')
            ? 'Start date cannot be in the past'
            : null}
        </div>
        <div style={styles.dateHelpText}>
          {error.includes('dateRangeError')
            ? 'End date cannot be before Start date'
            : null}
        </div>
      </Box>
    )
  }

  const selectDatesField = (item) => {
    const dateErrors = [
      'startDate',
      'endDate',
      'inThePast',
      'dateRangeError',
      'invalidDate',
    ]

    let errorCheck = []
    dateErrors.map((err) => {
      if (error.includes(err)) {
        errorCheck.push(err)
      }
    })

    return (
      <>
        <MultiDatePicker
          render={<SelectDateInput item={item} error={errorCheck} />}
          multiple
          sort
          value={product.selectDates}
          format="MMM DD, YYYY"
          onChange={(e) => handleChange('selectDates', e)}
        />
      </>
    )
  }
  const workshopField = (item) => {
    // workshop field is saved in format 'summer_2024', but dropdown options are for season only
    // checks if exisiting workshop value needs to be converted to dropdown option
    let value = product[item.key]
    if (value.includes('_')) {
      value = value.split('_')[0]
    }
    return (
      <FormControl
        fullWidth
        error={error.includes(item.key)}
        required={item.isRequired}
        disabled={product.students?.length > 0}
      >
        <InputLabel>{item.label}</InputLabel>
        <Select
          value={value}
          label={item.key}
          id={item.key}
          onChange={(e) => {
            handleChange(item.key, e.target.value)
          }}
        >
          {WORKSHOP_TYPES.map((option) => {
            return (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  const instructorField = (item) => {
    return (
      <FormControl
        fullWidth
        error={error.includes(item.key)}
        required={item.isRequired}
      >
        <InputLabel>{item.label}</InputLabel>
        <Select
          value={product.instructors}
          label={item.key}
          id={item.key}
          onChange={(e) => {
            handleChange(item.key, e.target.value)
          }}
          multiple
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((instructorId) => (
                <Chip
                  label={
                    instructors?.find((e) => e._id === instructorId)?.info?.name
                      ?.fName
                  }
                />
              ))}
            </Box>
          )}
        >
          {instructors.map((option) => {
            return (
              <MenuItem key={option._id} value={option._id}>
                {option.info.name.fName} {option.info.name.lName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  const searchField = (item) => {
    let array = null
    if (item.key === 'location' || item.key === 'course') {
      array = Object.values(item.data)
    } else {
      array = item.data
    }
    return (
      <>
        <Autocomplete
          value={
            product[item.key] ? { name: `${product[item.key + 'Name']}` } : null
          }
          autoSelect={true}
          options={array}
          getOptionLabel={(option) => (option.name ? option.name : ' ')} //temporary fix for dev database errors
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, value) => {
            handleChange(item.key, value._id + '&BREAK&' + value.name)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={item.label}
              id={item.key}
              error={error.includes(item.key)}
              key={item.key}
              required={item.isRequired}
            />
          )}
          disabled={product.students?.length > 0}
        />
      </>
    )
  }

  const formContent = () => {
    return (
      <>
        <DialogTitle>
          Enter Information
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
          {duplicateError ? (
            <Typography style={{ color: 'red' }}>
              You must change a field before duplicating
            </Typography>
          ) : (
            <></>
          )}
        </DialogTitle>

        <DialogContent>
          {product.students?.length > 0 ? (
            <Typography variant="caption">
              Some fields have been disabled because students are enrolled in
              this workshop
            </Typography>
          ) : (
            <></>
          )}

          <div style={styles.fieldWrapper}>
            {PRODUCT_FIELDS.map((item) => {
              if (item.key === 'location' || item.key === 'course') {
                return (
                  <div
                    key={item.key}
                    style={{ ...styles.fields, gridColumn: '1 / 3' }}
                  >
                    {searchField(item)}
                  </div>
                )
              } else if (item.key === 'startTime') {
                return (
                  <div
                    key={item.key}
                    style={{ ...styles.fields, gridColumn: '1 / 3' }}
                  >
                    {timeField(item)}
                  </div>
                )
              } else if (item.key === 'price' || item.key === 'capacity') {
                return (
                  <div key={item.key} style={{ ...styles.fields }}>
                    {standardField(item)}
                  </div>
                )
              } else if (item.key === 'workshop') {
                return (
                  <div
                    key={item.key}
                    style={{ ...styles.fields, gridColumn: '1 / 3' }}
                  >
                    {workshopField(item)}
                  </div>
                )
              } else if (item.key === 'instructors') {
                return (
                  <div
                    key={item.key}
                    style={{ ...styles.fields, gridColumn: '1 / 3' }}
                  >
                    {instructorField(item)}
                  </div>
                )
              } else if (item.key === 'selectDates') {
                return (
                  <div
                    key={item.key}
                    style={{ ...styles.fields, gridColumn: '1 / 3' }}
                  >
                    {selectDatesField(item)}
                  </div>
                )
              }
            })}
          </div>
          {outdatedAgeData ? (
            <Alert severity="info">
              The course age info for this workshop has been updated. Hit save
              to bring the workshop age info up to date.
            </Alert>
          ) : (
            <></>
          )}
        </DialogContent>

        <DialogActions>
          {disableSave ? <Typography>Loading...</Typography> : <></>}
          <Button
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              submit()
            }}
            disabled={disableSave}
          >
            Save
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <React.Fragment>
      {addWorkshop ? (
        <Paper>{formContent()}</Paper>
      ) : (
        <Dialog open fullScreen style={styles.tempWeb}>
          {formContent()}
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default ProductForm
