import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComp from '../util/loading'
import ProductForm from '../adminViews/products/productForm'
import { Box, Typography } from '@mui/material'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import WorkshopCard from './workshopCard'
import productHandler from '../../utils/productHandler'
import { setProduct, setWorkshops } from '../../store/workshopSlice'

const CourseCatalog = ({ week, dates, uID, toggleCustomer }) => {
  const dispatch = useDispatch()

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [showProductForm, setShowProductForm] = useState(false)

  const masterList = useSelector((state) => state.workshop.masterList)
  const filterList = useSelector((state) => state.workshop.filterList)
  const product = useSelector((state) => state.workshop.product)
  const workshops = useSelector((state) => state.workshop.workshops)

  const styles = {
    msg: {
      marginTop: '20px',
      textAlign: 'center'
    }
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/courseCatalog',
      title: 'Course Catalog'
    })
    ReactPixel.pageView()
  }, [])

  useEffect(() => {
    if (dates) {
      //Let admin see all dates, customers only see future dates
      let currentDates = toggleCustomer !== 'Admin' ? dates.slice(week) : dates
      let tmpProducts = {}
      //assigns filter list or master list to tmp products
      if (Object.keys(filterList).length > 0) {
        tmpProducts = filterList
      } else {
        currentDates.forEach((date) => {
          //gets start week and adds masterlist data to tmp
          tmpProducts[date] = masterList[date]
        })
      }
      //sorts tmp products by age
      let sortedByAge = {}
      Object.keys(tmpProducts).forEach((key) => {
        if (tmpProducts[key]) {
          let tmpArr = [...tmpProducts[key]]
          tmpArr.sort(
            (a, b) =>
              a.info.ageMin - b.info.ageMin + (a.info.ageMax - b.info.ageMax)
          )
          sortedByAge[key] = tmpArr
        }
      })
      setProducts(sortedByAge)
      setLoading(false)
  
    }
  }, [dates, filterList, masterList, week])

  /*Edit Product functions */
  const handleProductForm = (item) => {
    let formattedProduct = productHandler.formatProduct(item)
    dispatch(setProduct(formattedProduct))
    setShowProductForm(true)
  }

  const handleCloseProductForm = () => {
    setShowProductForm(false)
  }

  const handleProductUpdate = async (course) => {
    let resp = await productHandler.update(product, course, uID)
    if (resp.success) {
      let tmpWorkshops = [...workshops]

      let index = tmpWorkshops.findIndex((prod) => prod.id === resp.data.id)

      tmpWorkshops.splice(index, 1, resp.data)
      dispatch(setWorkshops(tmpWorkshops))

      handleCloseProductForm()
    } else {
      alert('failed creation')
    }
  }
  /*End edit product function */

  const sessionSection = (week, session) => {
    const sessionFilter = (product, time) => {
      if (time == 'Morning') {
        if (product.metadata.startTime < 12 && product.metadata.endTime <= 13) {
          return true
        } else {
          return false
        }
      } else if (time == 'Afternoon') {
        if (product.metadata.startTime >= 12) {
          return true
        } else {
          return false
        }
      } else if (time == 'Full Day') {
        if (product.metadata.startTime < 12 && product.metadata.endTime > 13) {
          return true
        } else {
          return false
        }
      }
    }
    return (
      <>
        {products[week].filter((product) => sessionFilter(product, session))
          .length > 0 ? (
          <>
            <Typography textAlign='left' variant='h4'>
              {session}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '100%', md: '50% 50%' }
              }}
            >
              {products[week]
                .filter((product) => sessionFilter(product, session))
                .map((product) => {
                  return (
                    <div style={{ margin: '10px' }} key={product.id}>
                      <WorkshopCard
                        workshop={product}
                        handleProductForm={handleProductForm}
                        toggleCustomer={toggleCustomer}
                      />
                    </div>
                  )
                })}
            </Box>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <Box sx={{ marginBottom: '100px', maxWidth: '1000px', width: '100%' }}>
        {products === undefined || Object.keys(products).length === 0 ? (
          <>
            {products !== undefined &&
            !loading &&
            Object.keys(products)?.every((key) => products[key].length == 0) ? (
              <div style={styles.msg}>
                <h3>No availability, check another course or week.</h3>
              </div>
            ) : (
              <>
                <div style={styles.msg}>
                  <LoadingComp />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {Object.keys(products).map((week) => {
              return (
                <React.Fragment key={week}>
                  {products[week].length > 0 ? (
                    <Box sx={{ marginBottom: '50px' }}>
                      <Typography
                        align='left'
                        variant='h2'
                        sx={{ marginTop: '20px' }}
                      >
                        {week}
                      </Typography>
                      {sessionSection(week, 'Morning')}
                      {sessionSection(week, 'Afternoon')}
                      {sessionSection(week, 'Full Day')}
                    </Box>
                  ) : null}
                </React.Fragment>
              )
            })}
          </>
        )}
        {showProductForm ? (
          <ProductForm
            handleClose={handleCloseProductForm}
            handleUpdate={handleProductUpdate}
            type={'edit'}
          ></ProductForm>
        ) : (
          <></>
        )}

        <Typography sx={{ padding: '5px 5px' }}>
          * - indicates camp runs for fewer than 5 days
        </Typography>
      </Box>
    </React.Fragment>
  )
}

export default CourseCatalog
