import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SecondaryNav from '../../component/util/secondaryNav'
import CompetitionsList from '../../component/competitionsList'
import CompetitionCalendar from '../../component/competitionCalendar'
import CompetitionEventsFilter from '../../component/competitionEventsFilter'
import { Box } from '@mui/material'

const RobotEventsSpreadsheet = () => {
  let permissions = useSelector((state) => state.login?.user?.permissions?.[0])
  const [teamSelectOptions, setTeamSelectOptions] = useState(() =>
    permissions >= 3 ? ['All'] : []
  )
  const [currTab, setCurrTab] = useState(0)
  const [teamEventData, setTeamEventData] = useState([])
  const [loading, setLoading] = useState(true)

  //Header configuration for Robot Events API
  const CONFIG = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + process.env.REACT_APP_BEARER_TOKEN
    }
  }

  let teamNumbers = [
    '1920A',
    '1920B',
    '6007X',
    '6007Y',
    '7700A',
    '7700B',
    '7700C',
    '7700E',
    '7700F',
    '7700H',
    '7700K',
    '7700N',
    '7700P',
    '7700R',
    '7700T',
    '7700V',
    '7700X',
    '7314A',
    '7899A',
    '7899B',
    '7899C',
    '7899G',
    '7899K',
    '7899T',
    '7899X',
    '7899Y'
  ]

  let afterDate = '2024-08-01'

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await getEventData()
      if (mounted && resp.length > 0) {
        setTeamEventData(resp)
        setLoading(false)
      }
    }
    handleAsync()
    return () => (mounted = false)
  }, [])

  function paginatedFetch(url, page, prevResp) {
    return fetch(`${url}page=${page}`, CONFIG)
      .then((teamsResp) => teamsResp.json())
      .then((jsonResp) => {
        const response = [...prevResp, ...jsonResp.data]
        if (jsonResp.meta.last_page !== jsonResp.meta.current_page) {
          page++
          return paginatedFetch(url, page, response)
        }
        return response
      })
  }

  async function getEventData() {
    //Get input value
    //Use team numbers to create url
    let queryString = ''
    teamNumbers.forEach((team) => (queryString += `number%5B%5D=${team}&`))
    let teamsPage = 1
    let teamsUrl = 'https://www.robotevents.com/api/v2/teams?' + queryString
    let teamResp = []
    //Fetch team Ids then events
    return await paginatedFetch(teamsUrl, teamsPage, teamResp)
      .then((allTeams) => {
        //Double check that the teams are actually RR teams
        const search = /rolling\srobots/i
        let filteredTeams = allTeams.filter((team) =>
          search.test(team.organization)
        )
        return filteredTeams
      })
      //Use team information to get all events after specified date
      .then((listOfTeams) => {
        return Promise.all(
          listOfTeams.map((team) => {
            setTeamSelectOptions((prev) =>
              prev.includes(team.number) ? [...prev] : [...prev, team.number]
            )
            let eventsUrl =
              'https://www.robotevents.com/api/v2/events?team%5B%5D=' +
              team.id +
              '&start=' +
              afterDate +
              'T00%3A00%3A00-00%3A00&'
            let eventsPage = 1
            let eventsResp = []
            return paginatedFetch(eventsUrl, eventsPage, eventsResp).then(
              (eventsResp) => {
                //Check that the team is actually still signed up for the event by checking all the teams for the event
                return Promise.all(
                  eventsResp.map((event) => {
                    let eventUrl =
                      'https://www.robotevents.com/api/v2/events/' +
                      event.id +
                      '/teams?number%5B%5D=' +
                      team.number
                    return fetch(eventUrl, CONFIG)
                      .then((checkEvent) => checkEvent.json())
                      .then((checkEvent) => {
                        if (checkEvent.data.length > 0) {
                          return event
                        } else {
                          return null
                        }
                      })
                  })
                ).then((events) => {
                  let filteredEvents = events.filter((event) => event !== null)
                  return { team: team, events: filteredEvents }
                })
              }
            )
          })
        )
      })
  }
  const [programFilter, setProgramFilter] = useState('V5RC')
  const [selectedTeam, setSelectedTeam] = useState('')

  let tabs = [
    {
      label: 'List View',
      content: (
        <Box
          sx={{
            margin: 'auto auto',
            marginBottom: '50px',
            width: { xs: '90%', sm: '80%' },
            maxWidth: '1000px'
          }}
        >
          <CompetitionEventsFilter
            loading={loading}
            teamSelectOptions={teamSelectOptions}
            setSelectedTeam={setSelectedTeam}
            programFilter={programFilter}
            setProgramFilter={setProgramFilter}
          />
          <CompetitionsList
            loading={loading}
            teamSelectOptions={teamSelectOptions}
            teamEventData={teamEventData}
            programFilter={programFilter}
            selectedTeam={selectedTeam}
            currTab={currTab}
          />
        </Box>
      )
    }
  ]
  if (permissions >= 3) {
    tabs.push({
      label: 'Calendar View',
      content: (
        <Box
          sx={{
            margin: 'auto auto',
            marginBottom: '50px',
            width: { xs: '90%', sm: '80%' },
            maxWidth: '1000px'
          }}
        >
          <CompetitionEventsFilter
            loading={loading}
            teamSelectOptions={teamSelectOptions}
            setSelectedTeam={setSelectedTeam}
            programFilter={programFilter}
            setProgramFilter={setProgramFilter}
          />
          <CompetitionCalendar
            teamEventData={teamEventData}
            programFilter={programFilter}
            selectedTeam={selectedTeam}
            loading={loading}
            currTab={currTab}
          />
        </Box>
      )
    })
  }

  return (
    <>
      <SecondaryNav
        title={'Competition Events'}
        tabs={tabs}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      {tabs[currTab].content}
    </>
  )
}

export default RobotEventsSpreadsheet
