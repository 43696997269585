import { createSlice } from '@reduxjs/toolkit'

export const workshopSlice = createSlice({
  name: 'workshop',
  initialState: {
    workshops: [],
    date: null,
    filteredWorkshops: [],
    masterList: [],
    filterList: [],
    gridData: {},
    location: null,
    product: {
      workshop: '',
      location: '',
      course: '',
      startDate: null,
      endDate: null,
      selectDates: [],
      startTime: '9:00',
      endTime: '12:00',
      cap: 10,
      price: '',
      instructors: []
    }
  },
  reducers: {
    setWorkshops: (state, action) => {
      state.workshops = action.payload
    },
    setFilteredWorkshops: (state, action) => {
      state.filteredWorkshops = action.payload
    },
    setDate: (state, action) => {
      state.date = action.payload
    },
    setMaster: (state, action) => {
      state.masterList = action.payload
    },
    setFilter: (state, action) => {
      state.filterList = action.payload
    },
    setGrid: (state, action) => {
      state.gridData.bucket = action.payload.bucket
      state.gridData.names = action.payload.names
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    clearWorkshop: (state, action) => {
      state.location = null

      state.masterList = []
      state.filterList = []
    },
    updateWorkshop: (state, action) => {
      //Update Week View
      let tmpMaster = { ...state.masterList }
      let oldIndex = tmpMaster[action.payload.oldBucket].findIndex(
        (elm) => elm.id === action.payload.oldWorkshop.id
      )

      let newIndex = tmpMaster[action.payload.newBucket].findIndex(
        (elm) => elm.id === action.payload.newWorkshop.id
      )
      tmpMaster[action.payload.oldBucket][oldIndex]['info'] =
        action.payload.oldWorkshop.info

      tmpMaster[action.payload.newBucket][newIndex]['info'] =
        action.payload.newWorkshop.info
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    resetProduct: (state, action) => {
      state.product = {
        workshop: '',
        location: '',
        course: '',
        startDate: null,
        endDate: null,
        selectDates: [],
        startTime: null,
        endTime: null,
        cap: 10,
        price: '',
        instructors: []
      }
    }
  }
})

export const {
  setWorkshops,
  setFilteredWorkshops,
  setMaster,
  setFilter,
  setLocation,
  setDate,
  clearWorkshop,
  setGrid,
  updateFilterList,
  updateMasterList,
  updateWorkshop,
  setProduct,
  resetProduct
} = workshopSlice.actions

export default workshopSlice.reducer
