import { useState, useEffect } from 'react'
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { attendanceAdd, getAttendance, attendanceEdit } from '../../api/admin'
import { pink, green } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import dateHandler from '../../utils/dateHandler'
import { Error } from '@mui/icons-material'

const AttendanceRow = ({ uID, student, product, dates, index }) => {
  const [attendanceArr, setAttendanceArr] = useState([])
  //Check to prevent double api call on slow internet speeds
  let apiOkay = true
  //Check if instructor missed attendance for any student
  function getDatesAttended(courseDates, studentDates) {
    let tmpArr = []
    courseDates.forEach((courseDate) => {
      let dateStr = courseDate.split('T')
      let check = false
      for (let i = 0; i < studentDates.length; i++) {
        if (studentDates[i].date === dateStr[0]) {
          check = true
          tmpArr.push(studentDates[i].status)
        }
      }
      if (!check) {
        tmpArr.push('missed')
      }
    })
    setAttendanceArr(tmpArr)
  }
  //Get dates student attended course
  useEffect(() => {
    async function handleAsync() {
      let resp = await getAttendance({
        prodID: product._id,
        studentID: student.id,
        uID: uID,
      })
      if (resp.success) {
        getDatesAttended(dates, resp.data)
      } else {
        alert('Failed to get attendance. Please reload page')
      }
    }
    handleAsync()
  }, [])

  const handleClick = async (prodID, studentID, date, status, index) => {
    if (status === 'present' && apiOkay) {
      apiOkay = false
      let resp = await attendanceEdit({
        uID: uID,
        prodID: prodID,
        date: date,
        studentID: studentID,
        status: 'absent',
      })
      if (resp.success) {
        setAttendanceArr((prevState) => {
          const newState = prevState.map((obj, objIndex) => {
            if (objIndex === index) {
              return 'absent'
            }
            return obj
          })
          return newState
        })
        apiOkay = true
      } else {
        alert('Failed to submit attendance')
        apiOkay = true
      }
    } else if (status === 'absent' && apiOkay) {
      apiOkay = false
      let resp = await attendanceEdit({
        uID: uID,
        prodID: prodID,
        date: date,
        studentID: studentID,
        status: 'present',
      })
      if (resp.success) {
        setAttendanceArr((prevState) => {
          const newState = prevState.map((obj, objIndex) => {
            if (objIndex === index) {
              return 'present'
            }
            return obj
          })
          return newState
        })
        apiOkay = true
      } else {
        alert('Failed to submit attendance')
        apiOkay = true
      }
    } else if (apiOkay) {
      apiOkay = false
      let resp = await attendanceAdd({
        uID: uID,
        prodID: prodID,
        date: date,
        studentID: studentID,
        status: 'present',
      })
      if (resp.success) {
        setAttendanceArr((prevState) => {
          const newState = prevState.map((obj, objIndex) => {
            if (objIndex === index) {
              return 'present'
            }
            return obj
          })
          return newState
        })
        apiOkay = true
      } else {
        alert('Failed to submit attendance')
        apiOkay = true
      }
    }
  }

  const dateFormatter = (item) => {
    if (item) {
      let day = new Date(item).toDateString().split(' ')
      return day[0]
    } else {
      return item
    }
  }

  const creditConfirmationNotice = () => {
    if (
      typeof student.creditConfirmed === 'number' &&
      student.creditConfirmed !== 1
    ) {
      let text = ''
      let color = ''
      if (student.creditConfirmed === 0) {
        text = 'Payment is unconfirmed'
        color = 'warning'
      } else if (student.creditConfirmed === 2) {
        text = 'Payment has been rejected'
        color = 'primary'
      }
      return (
        <Tooltip title={text}>
          <Error color={color} fontSize="small" sx={{ marginLeft: '5px' }} />
        </Tooltip>
      )
    }
  }
  return (
    <div key={index}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body1"
          display="inline"
          style={{ paddingLeft: '8px' }}
        >
          {student.name}
        </Typography>
        {creditConfirmationNotice()}
      </div>
      <div style={{ display: 'flex' }}>
        {attendanceArr.map((status, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <div>{dateFormatter(dates[index])}</div>
            <Checkbox
              key={index}
              checked={
                status === 'present' || status === 'absent' ? true : false
              }
              sx={
                status === 'absent'
                  ? { '&.Mui-checked': { color: pink[600] } }
                  : { '&.Mui-checked': { color: green[600] } }
              }
              disabled={status === 'future' ? true : false}
              onClick={() => {
                handleClick(
                  product._id,
                  student.id,
                  dates[index],
                  status,
                  index
                )
              }}
              style={{ paddingTop: '0' }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const AttendanceModal = ({ product, handleClose, studentData }) => {
  const uID = useSelector((state) => state.login.user._id)
  const dates = []
  const startDate = dateHandler.getDateTimeObj(product.metadata.startDate)
  const endDate = dateHandler.getDateTimeObj(product.metadata.endDate)

  //Put dates of course into array
  let day = startDate
  while (day <= endDate) {
    dates.push(day.toISO())
    day = day.plus({ days: 1 })
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        Attendance for {product.metadata.courseName}
      </DialogTitle>
      <DialogContent>
        {studentData.map((student, index) => (
          <AttendanceRow
            index={index}
            student={student.student}
            product={product.info || product}
            uID={uID}
            dates={dates}
          />
        ))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            border: '2px solid gray',
            borderRadius: '7px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <div>Present</div>
            <Checkbox
              style={{ paddingTop: '0' }}
              checked={true}
              sx={{
                '&.Mui-checked': {
                  color: green[600],
                },
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <div>Absent</div>
            <Checkbox
              style={{ paddingTop: '0' }}
              checked={true}
              sx={{
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <div>Not Taken</div>
            <Checkbox style={{ paddingTop: '0' }} checked={false} />
          </div>
        </div>
        <Typography
          variant="subtitle2"
          style={{ textAlign: 'center', marginBottom: '10px' }}
        >
          Click an empty box twice to mark absent.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default AttendanceModal
